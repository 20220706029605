import dynamic from "next/dynamic";
import theme from "@/styles/themes/theme";
import getConfig from "next/config";

/* Reference https://morioh.com/p/353293c85e94 */
export const BlipChat = dynamic(
  () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return import("blip-chat-widget").then((mod) => {
      const { BlipChat: BlipChatWidget } = mod;
      const url = location.href.split("/");
      const pagina = url[url.length - 1];
      const { BLIP_APP_KEY, BLIP_CUSTOM_COMMON_URL } = getConfig().publicRuntimeConfig;

      new BlipChatWidget()
        .withAppKey(BLIP_APP_KEY)
        .withButton({ color: theme.palette.warning.main })
        .withCustomCommonUrl(BLIP_CUSTOM_COMMON_URL)
        .withEventHandler(BlipChatWidget.LOAD_EVENT, function () {
          const bubbleMsg = document.getElementById("bubble-msg");
          bubbleMsg ? bubbleMsg.click() : null;
        })
        .withCustomMessageMetadata({
          userInformations: `${pagina}`
        })
        .build();
      setTimeout(() => {
        const bubbleMsg = document.getElementById("bubble-msg");
        bubbleMsg ? bubbleMsg.click() : null;
      }, 5000);
      return () => null;
    });
  },
  {
    ssr: false
  }
);
