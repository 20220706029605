/* eslint-disable @typescript-eslint/no-explicit-any */
import { signOut, useSession } from "next-auth/react";
import { useRouter } from "next/router";
import React from "react";

import { useDisclose } from "@/hooks/utilHooks";
import theme from "@/styles/themes/theme";
import {
  Alert,
  Box,
  ClickAwayListener,
  Collapse,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Stack,
  Tooltip,
  Typography
} from "@mui/material";
import { ClipboardText, IdentificationCard, SignOut, X } from "@phosphor-icons/react";

import { MyRegistration } from "../../../MyRegistration";
import { styles } from "./styles";
import { UserMenuProps } from "./types";
import { UserInfoBalance, UserInfoName } from "./UserInfo/UserInfo";

const IconMenu = ({ Icon }: { Icon: React.ElementType }) => <Icon fontSize={24} color={theme.palette.secondary.main} />;

export const UserMenu: React.FC<UserMenuProps> = ({ isOpen, children, onClose, notification }) => {
  const router = useRouter();
  const { status } = useSession();
  const notificationDisclose = useDisclose(true);
  const myRegistration = useDisclose();

  const handleLogout = async () => {
    await signOut();
  };

  if (status == "unauthenticated") {
    return null;
  }

  const handleExtract = () => {
    router.push("/extrato");
    onClose();

    try {
      if (typeof window !== "undefined" && (window as any).dataLayer) {
        (window as any).dataLayer.push({
          event: "extrato"
        });
        console.log("Evento de extrato enviado para o Google Tag Manager");
      }
    } catch (error) {
      console.error("Erro ao enviar o evento de extrato para o Google Tag Manager", error);
    }
  };

  const handleDataUser = () => {
    myRegistration.onOpen();

    try {
      if (typeof window !== "undefined" && (window as any).dataLayer) {
        (window as any).dataLayer.push({
          event: "meusdados"
        });
        console.log("Evento de clique no perfil enviado para o Google Tag Manager");
      }
    } catch (error) {
      console.error("Erro ao enviar o evento de clique no perfil para o Google Tag Manager", error);
    }
  };

  return (
    <>
      <ClickAwayListener onClickAway={onClose}>
        <Box css={styles.usermenu}>
          <Tooltip
            open={isOpen}
            PopperProps={{
              disablePortal: true
            }}
            onClose={onClose}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            arrow
            placement="bottom-end"
            title={
              <>
                <Stack px={2} py={1} display={{ xs: "block", md: "none" }}>
                  <UserInfoName color="secondary" />
                  <UserInfoBalance />
                </Stack>

                <Divider sx={{ display: { xs: "block", md: "none" } }} />

                <Collapse in={notificationDisclose.isOpen && notification?.data}>
                  <Alert
                    severity="info"
                    action={
                      <IconButton aria-label="close" color="inherit" size="small" onClick={notificationDisclose.onClose}>
                        <X fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    <Typography variant="body2" textTransform="none">
                      {notification?.data}
                    </Typography>
                  </Alert>
                </Collapse>

                <MenuList>
                  <MenuItem>
                    <ListItemIcon>
                      <IconMenu Icon={ClipboardText} />
                    </ListItemIcon>
                    <ListItemText onClick={handleExtract} css={styles.usermenu__item}>
                      Ver extrato
                    </ListItemText>
                  </MenuItem>

                  <Divider />

                  <MenuItem onClick={handleDataUser}>
                    <ListItemIcon>
                      <IconMenu Icon={IdentificationCard} />
                    </ListItemIcon>
                    <ListItemText css={styles.usermenu__item}>Meu cadastro</ListItemText>
                  </MenuItem>

                  <Divider />

                  <MenuItem>
                    <ListItemIcon>
                      <IconMenu Icon={SignOut} />
                    </ListItemIcon>
                    <ListItemText css={styles.usermenu__item} onClick={handleLogout}>
                      Sair
                    </ListItemText>
                  </MenuItem>
                </MenuList>
              </>
            }
          >
            {children}
          </Tooltip>
        </Box>
      </ClickAwayListener>
      <MyRegistration isOpen={myRegistration.isOpen} onClose={myRegistration.onClose} />
    </>
  );
};
