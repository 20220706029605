import theme from "@/styles/themes/theme";
import { css } from "@emotion/react";

export const styles = {
  modal: css`
    padding: 0;
    .MuiPaper-root {
      border-radius: 16px;
      background-color: ${theme.palette.primary.main};
    }
    @media print {
      .MuiDialogActions-root {
        display: none;
      }
    }
  `,
  modal__title: css`
    background-color: white;
    @media print {
      display: none;
    }
  `,
  modal__children: css`
    display: flex;
    flex-direction: row;
  `
};
