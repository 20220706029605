import { useShoppingCart } from "@/context/ShoppingCartContext";
import theme from "@/styles/themes/theme";
import { Button, Stack, Typography } from "@mui/material";
import { ShoppingCart as Cart } from "@phosphor-icons/react";

import { styles } from "./styles";

export const EmptyShoppingCart: React.FC = () => {
  const { cartDisclose } = useShoppingCart();

  return (
    <>
      <Stack direction="column" my={2} px={4} alignItems="center" justifyContent="center" spacing={1}>
        <Cart size={65} color={theme.palette.text.secondary} css={styles.drawer__icon_shoppingcart} />
        <Typography color={theme.palette.text.secondary} fontWeight={500} fontSize={{ xs: 14, sm: 16, md: 16 }}>
          Seu carrinho está vazio
        </Typography>
      </Stack>

      <Button variant="outlined" size="small" color="secondary" onClick={cartDisclose.onClose} sx={{ mt: 2, mx: 4 }}>
        <Typography color={theme.palette.secondary.main} fontSize={15} fontWeight={600}>
          Continuar comprando
        </Typography>
      </Button>
    </>
  );
};
