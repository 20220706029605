import theme from "@/styles/themes/theme";
import { css } from "@emotion/react";

export const styles = {
  header: css`
    position: fixed;
  `,
  header__toolbar: css`
    display: flex;
    justify-content: space-between;
  `,
  header__box_mr: css`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
  `,
  header__box: css`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;

    @media (max-width: 950px) {
      gap: 2px;
    }
  `,
  header__logo: css`
    border: 1px solid ${theme.palette.common.white};
    border-radius: 30%;
  `,
  header__badge: css`
    .MuiBadge-badge {
      background-color: white;
      color: ${theme.palette.warning.dark};
      right: 6px;
      top: 6px;
    }
  `,
  header__button: css`
    width: 45px;
    height: 45px;
    border-radius: 50%;
    padding: 0px;
    background-color: ${theme.palette.warning.main};
    &:hover {
      background-color: ${theme.palette.warning.dark};
    }
  `,
  header__account: css`
    display: flex;
    align-items: center;
  `,
  header__list: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
  `,
  header__listitembutton: css`
    display: flex;
    gap: 8px;
  `
};
