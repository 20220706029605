import axios from "axios";
import { useEffect, useState } from "react";

import useDebounce from "@/hooks/useDebounce";
import { useLoader } from "@/hooks/utilHooks";
import { SearchResult } from "@/types/search";
import { LinkUtils } from "@/utils/linkUtils";
import { Autocomplete, Box, CircularProgress, Divider, Link, Paper, TextField } from "@mui/material";
import { MagnifyingGlass } from "@phosphor-icons/react";

import { styles } from "./styles";

const INPUT_DEBOUNCE_TIME = 500;

export const TextFieldSearch: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<SearchResult[]>([]);
  const [inputValue, setInputValue] = useState("");
  const { loading, showLoader, hideLoader } = useLoader();

  const debouncedInputValue = useDebounce(inputValue, INPUT_DEBOUNCE_TIME);

  const handleOptionSelect = (option: SearchResult | null) => {
    if (!option) {
      return;
    }

    LinkUtils.openLink(option.url);
  };

  useEffect(() => {
    const search = (debouncedInputValue ?? "").trim();

    if (!search) {
      setOptions([]);
      return;
    }

    const fetch = async () => {
      showLoader();

      const { data } = await axios.post<SearchResult[]>("/api/search", {
        query: search
      });

      setOptions(data);
      hideLoader();
    };

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedInputValue]);

  return (
    <Autocomplete
      id="search"
      open={open && inputValue.length > 0}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      clearOnBlur={false}
      clearOnEscape={false}
      blurOnSelect
      onInputChange={(_, value) => setInputValue(value)}
      onChange={(_, value) => handleOptionSelect(value as SearchResult)}
      getOptionLabel={(option) => (option as SearchResult).title}
      options={options}
      loading={loading}
      filterOptions={(options) => options}
      filterSelectedOptions={false}
      noOptionsText="Não encontramos o que você está buscando."
      PaperComponent={({ children }) => <Paper style={{ borderRadius: "6px" }}>{children}</Paper>}
      css={styles.inputbase}
      clearText="Limpar"
      componentsProps={{
        popupIndicator: { sx: { display: "none" } },
        clearIndicator: {
          sx: {
            visibility: inputValue ? "visible" : "hidden"
          }
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Buscar"
          InputProps={{
            ...params.InputProps,
            startAdornment: <MagnifyingGlass size={24} color="#0045B5" />,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="info" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
          css={styles.textfield}
        />
      )}
      renderOption={(props, option, state) => (
        <>
          <Link
            css={styles.option}
            href={option.url}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            color="inherit"
          >
            <Box component="li" {...props} css={styles.title}>
              {option.title}
            </Box>
          </Link>
          {state.index < options.length - 1 && <Divider component="li" css={styles.divider} />}
        </>
      )}
    />
  );
};
