/* eslint-disable @typescript-eslint/no-empty-function */
import "@/styles/globals.css";
import "../../polyfill";

import { SessionProvider } from "next-auth/react";
import { AppProps } from "next/app";
import getConfig from "next/config";
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { Toaster } from "sonner";

import { BlipChat } from "@/components/BlipChat";
import { RecaptchaV3Wrapper } from "@/components/RecaptchaV3Wrapper/RecaptchaV3Wrapper";
import { LoginProvider } from "@/context/LoginContext";
import { ShoppingCartProvider } from "@/context/ShoppingCartContext";
import theme from "@/styles/themes/theme";
import { LayoutDefault } from "@/templates/LayoutDefault";
import { datadogRum } from "@datadog/browser-rum";
import { EmotionCache } from "@emotion/react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { Box } from "@mui/material";
import { AppScripts } from "@/components/AppScripts";

const DATADOG_IGNORE_LIST = ["https://cdn.cookielaw.org"];

datadogRum.init({
  applicationId: process.env.NEXT_PUBLIC_DATADOG_RUM_APPLICATION_ID || "",
  clientToken: process.env.NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN || "",
  site: process.env.NEXT_PUBLIC_DATADOG_RUM_SITE || "",
  service: process.env.NEXT_PUBLIC_DATADOG_RUM_SERVICE || "",
  env: process.env.NEXT_PUBLIC_DATADOG_RUM_ENV || "",
  version: process.env.NEXT_PUBLIC_DATADOG_RUM_VERSION || "",
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
  allowedTracingUrls: [(url: string) => !DATADOG_IGNORE_LIST.some((item) => url.startsWith(item))]
});

datadogRum.startSessionReplayRecording();

const { publicRuntimeConfig } = getConfig();

const PAGE_TITLE = publicRuntimeConfig?.SITE_NAME || "Km de Vantagens";

export interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

export default function MyApp(props: MyAppProps) {
  const { Component, pageProps } = props;
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = () => {
      document?.querySelector("body")?.scrollTo(0, 0);
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    console.log("KMV_SYSTEM_VERSION", publicRuntimeConfig?.PACKAGE_VERSION);
  }, []);

  return (
    <SessionProvider session={pageProps.session}>
      <>
        <Head>
          <title>{pageProps?.pageTitle ? `${pageProps.pageTitle} | ${PAGE_TITLE}` : PAGE_TITLE}</title>
          <meta name="viewport" content="initial-scale=1, width=device-width" />
        </Head>
        <AppScripts />

        <RecaptchaV3Wrapper>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <ShoppingCartProvider>
              <LoginProvider>
                {/* if ignorePageTemplate prop is received, don't render the page template */}
                {pageProps.ignorePageTemplate ? (
                  <Component {...pageProps} />
                ) : (
                  <LayoutDefault
                    menuItems={pageProps.menuItems}
                    simulatorAccumulationInformativeText={pageProps.simulatorAccumulationInformativeText}
                    locationCompany={pageProps.locationCompany}
                  >
                    <Component {...pageProps} />
                  </LayoutDefault>
                )}
              </LoginProvider>
            </ShoppingCartProvider>
            {/* if ignorePageTemplate prop is received, don't render the blip chat */}
            {!pageProps.ignorePageTemplate ? (
              <>
                <BlipChat /> {/* Aqui está o componente BlipChat */}
                <div className="message-bubble">
                  <p>Qual é a sua dúvida?</p>

                  <p>Vamos conversar sobre isso!</p>

                  <Box
                    component="span"
                    className="close-button"
                    id="bubble-msg"
                    onClick={(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
                      const parentElement = event.currentTarget.parentElement;
                      if (parentElement) {
                        parentElement.style.display = "none";
                      }
                    }}
                  />
                </div>
              </>
            ) : null}
          </ThemeProvider>
        </RecaptchaV3Wrapper>

        <Toaster closeButton position="top-center" visibleToasts={1} style={{ top: 80, borderRadius: 8 }} />
      </>
    </SessionProvider>
  );
}
