/* eslint-disable react/no-unknown-property */
import { Checkbox } from "@/components/Inputs/Checkbox";
import theme from "@/styles/themes/theme";
import { css } from "@emotion/react";
import { Grid, Stack, Typography } from "@mui/material";
import { FilePdf } from "@phosphor-icons/react";

const styles = {
  label: css`
    @media (max-width: 600px) {
      font-size: 12px;
    }
  `
};

export const Terms = () => {
  return (
    <>
      <Grid item>
        <Stack direction="row" spacing={1} mb={1}>
          <FilePdf color={theme.palette.secondary.main} size={18} />
          <Typography fontSize={{ xs: 10, sm: 13, md: 13 }} fontWeight={500} color={theme.palette.secondary.main} textTransform="uppercase">
            Confira o Regulamento Completo
          </Typography>
        </Stack>

        <Checkbox
          id="regulation"
          name="regulation[0].accepted"
          label={<span css={styles.label}>Já li e aceito o Regulamento do programa de Fidelidade Km de Vantagens.</span>}
          disabled
        />
      </Grid>

      <Grid item>
        <Stack direction="row" spacing={1} mb={1}>
          <FilePdf color={theme.palette.secondary.main} size={18} />
          <Typography fontSize={{ xs: 10, sm: 13, md: 13 }} fontWeight={500} color={theme.palette.secondary.main} textTransform="uppercase">
            Confira a política de privacidade KM de Vantagens
          </Typography>
        </Stack>

        <Checkbox
          id="term"
          name="term[0].accepted"
          label={<span css={styles.label}>Já li e aceito os termos da Política de Privacidade Km de Vantagens.</span>}
          disabled
        />
      </Grid>
    </>
  );
};
