import { useController, UseControllerProps } from "react-hook-form";

import { Checkbox as MaterialCheckbox, CheckboxProps, FormControl, FormControlLabel, FormControlLabelProps, FormHelperText } from "@mui/material";

interface Props extends CheckboxProps, UseControllerProps {
  name: string;
  defaultValue?: string | number | readonly string[] | undefined;
  label?: React.ReactNode;
  labelPlacement?: FormControlLabelProps["labelPlacement"];
}

export const Checkbox: React.FC<Props> = ({ name, rules, shouldUnregister, defaultValue, control, label, labelPlacement, ...props }) => {
  const { field, fieldState } = useController({
    name,
    rules,
    shouldUnregister,
    defaultValue,
    control
  });

  return (
    <FormControl error={Boolean(fieldState.error?.message)} required={props.required}>
      <FormControlLabel
        value={field.value}
        checked={field.value}
        labelPlacement={labelPlacement}
        control={<MaterialCheckbox inputProps={{ "aria-label": "controlled" }} onChange={field.onChange} onBlur={field.onBlur} {...props} />}
        //color="primary"

        label={label || ""}
      />
      <FormHelperText>{fieldState.error?.message}</FormHelperText>
    </FormControl>
  );
};
