import clsx from "clsx";
import { useRouter } from "next/router";
import React from "react";

import { LinkUtils } from "@/utils/linkUtils";
import MuiLink from "@mui/material/Link";
import { styled } from "@mui/material/styles";

import { NextLinkComposed } from "../NextLinkComposed";
import { NextMuiLinkProps } from "./types";

/* Add support for the sx prop for consistency with the other branches. */
const Anchor = styled("a")({});

/**
 * A styled version of the Next.js Link component:
 * https://nextjs.org/docs/api-reference/next/link
 */
export const NextMuiLink = React.forwardRef<HTMLAnchorElement, NextMuiLinkProps>(function Link(props, ref) {
  const {
    activeClassName = "active",
    as,
    className: classNameProps,
    href,
    legacyBehavior,
    linkAs: linkAsProp,
    locale,
    noLinkStyle,
    prefetch,
    replace,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    role, // Link don't have roles.
    scroll,
    shallow,
    ...other
  } = props;

  const router = useRouter();
  const pathname = typeof href === "string" ? href : href.pathname;
  const className = clsx(classNameProps, {
    [activeClassName]: router.pathname === pathname && activeClassName
  });

  const isExternal = !LinkUtils.isInternalLink(href as string);

  if (isExternal) {
    if (noLinkStyle) {
      return <Anchor className={className} href={href as string} ref={ref} target="_blank" {...other} />;
    }

    return <MuiLink className={className} href={href as string} ref={ref} target="_blank" {...other} />;
  }

  const linkAs = linkAsProp || as;
  const nextjsProps = {
    to: href,
    linkAs,
    replace,
    scroll,
    shallow,
    prefetch,
    legacyBehavior,
    locale
  };

  if (noLinkStyle) {
    return <NextLinkComposed className={className} ref={ref} {...nextjsProps} {...other} />;
  }

  return <MuiLink component={NextLinkComposed} className={className} ref={ref} {...nextjsProps} {...other} />;
});
