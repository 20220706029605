import Image from "next/image";
import { MouseEvent } from "react";

import AmPmLogo from "@/assets/img/logo-ampm.png";
import JetOilLogo from "@/assets/img/logo-jetoil.png";
import theme from "@/styles/themes/theme";
import {
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Slider,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery
} from "@mui/material";
import { GasPump } from "@phosphor-icons/react";

import { styles } from "./styles";
import { PanelSimulationProps } from "./types";

function valueLabelFormat(value: number) {
  return `R$ ${value.toLocaleString("pt-BR")} / mês`;
}

export const PanelSimulation = ({
  value,
  setValue,
  place,
  setPlace,
  methodPayment,
  setMethodPayment,
  establishment,
  setEstablishment
}: PanelSimulationProps) => {
  const isDownSm = useMediaQuery(theme.breakpoints.down("sm"));

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number);
  };

  const handleEstablishment = (event: MouseEvent<HTMLElement>, newEstablishment: string | null) => {
    if (newEstablishment != null) {
      setEstablishment(newEstablishment);
    }
  };

  const handlePLace = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPlace(event.target.value);
  };

  const handleMethodPayment = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMethodPayment(event.target.value);
  };
  return (
    <Stack spacing={2} css={styles.modal__stack_block}>
      <Typography fontSize={{ xs: 14, sm: 16, md: 16 }} color={theme.palette.secondary.main}>
        Arraste o indicador abaixo, marque quanto costuma gastar mensalmente nos postos participantes, lojas AmPm e Jet Oil e veja quantos pontos KMV
        você pode acumular.
      </Typography>

      <Divider css={styles.modal__divider} />

      <Typography fontSize={14} fontWeight={600} color={theme.palette.text.primary}>
        Valor gasto
      </Typography>

      <Stack px={1}>
        <Slider
          value={value}
          max={1000}
          onChange={handleSliderChange}
          valueLabelDisplay="on"
          valueLabelFormat={valueLabelFormat}
          css={styles.modal__slider}
        />
      </Stack>

      <Typography fontSize={20} fontWeight={500} color={theme.palette.secondary.main}>
        Comprando:
      </Typography>

      <ToggleButtonGroup
        exclusive
        value={establishment}
        onChange={handleEstablishment}
        orientation={isDownSm ? "vertical" : "horizontal"}
        css={styles.modal__toggleButton}
      >
        <ToggleButton value="combustivel" sx={{ flex: 1 }}>
          <GasPump size={24} color={theme.palette.secondary.main} />
          <Typography fontSize={15} fontWeight={500} color={theme.palette.secondary.main} ml={1}>
            COMBUSTÍVEL
          </Typography>
        </ToggleButton>

        <ToggleButton value="ampm" sx={{ flex: 1 }}>
          <Image src={AmPmLogo} alt="Logo do AmPm" width={50} height={24} />
        </ToggleButton>

        <ToggleButton value="jetoil" sx={{ flex: 1 }}>
          <Image src={JetOilLogo} alt="Logo do JetOil" width={38} height={24} />
        </ToggleButton>
      </ToggleButtonGroup>

      <FormControl>
        <FormLabel>
          <Typography fontSize={20} fontWeight={500} color={theme.palette.secondary.main}>
            Local:
          </Typography>
        </FormLabel>
        <RadioGroup defaultValue="favoriteGasStation" onChange={handlePLace} value={place}>
          <Stack spacing={1} direction="column">
            <Stack direction={{ xs: "column", sm: "row", md: "row" }}>
              <FormControlLabel value="favoriteGasStation" control={<Radio />} label="no Posto Favorito" />
              <FormControlLabel value="anyGasStation" control={<Radio />} label="em Qualquer Posto Participante" />
            </Stack>
          </Stack>
        </RadioGroup>
      </FormControl>

      <FormControl>
        <FormLabel>
          <Typography fontSize={20} fontWeight={500} color={theme.palette.secondary.main}>
            Pagando:
          </Typography>
        </FormLabel>
        <RadioGroup defaultValue="ipirangaCreditCard" onChange={handleMethodPayment} value={methodPayment}>
          <Stack spacing={1} direction="column">
            <Stack direction={{ xs: "column", sm: "row", md: "row" }}>
              <FormControlLabel value="ipirangaCreditCard" control={<Radio />} label="com Cartão Ipiranga" />
              <FormControlLabel value="anyCreditCard" control={<Radio />} label="com Outro Cartão" />
            </Stack>
            <Stack direction={{ xs: "column", sm: "row", md: "row" }}>
              <FormControlLabel value="cash" control={<Radio />} label="com Dinheiro" />
              <FormControlLabel value="abasteceAi" control={<Radio />} label="com app KMV" />
            </Stack>
          </Stack>
        </RadioGroup>
      </FormControl>
    </Stack>
  );
};
