import { css } from "@emotion/react";
import theme from "@/styles/themes/theme";

export const drawerWidth = 260;

export const styles = {
  menu: css`
    display: flex;
  `,
  menu__muimenu: css`
    width: ${drawerWidth}px;
    flex-shrink: 0;
    overflow: auto;
    .MuiDrawer-paper {
      width: ${drawerWidth}px;
      box-sizing: border-box;
      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: ${theme.palette.grey[400]};
        border-radius: 3px;
      }
      &::-webkit-scrollbar-track {
        background-color: #eee;
      }
    }
  `,
  menu__divider: css`
    border: 1px solid rgba(0, 0, 0, 0.12);
    margin-left: 5px;
    margin-right: 5px;
  `,
  menu__list: css`
    padding-top: 15px;
    padding-bottom: 0;
    padding-left: 5px;
    padding-right: 5px;
  `,
  list__listitem: css`
    padding-left: 5px;
  `,
  menu__icon: css`
    padding: 4;
    margin-bottom: 3;
  `,
  menu__icon_airplane: css`
    padding: 4;
    margin-bottom: 3;
    transform: rotate(45deg);
  `
};
