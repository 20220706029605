import Router from "next/router";

/* Open a internal or external URL */
const openLink = (link?: string) => {
  if (!link) {
    return;
  }

  /* Check if the link starts with "/" or belongs to the application's domain */
  if (link.startsWith("/") || link?.includes(window.location.hostname)) {
    /* Navigate within the application */
    Router.push(link);
  } else if (link.startsWith("http") || link.startsWith("www")) {
    /* Check if the link is external and open it in a new tab */
    window.open(link, "_blank");
  } else {
    /* If not, add the initial slash and navigate within the application */
    Router.push(`/${link}`);
  }
};

/* Open a internal or external URL */
const isInternalLink = (link?: string) => {
  if (!link) {
    return true;
  }

  /* Check if the link starts with "/" or belongs to the application's domain */
  if (link.startsWith("/")) {
    /* Navigate within the application */
    return true;
  } else if (link.startsWith("http") || link.startsWith("www")) {
    /* Check if the link is external and open it in a new tab */
    return false;
  } else {
    /* If not, add the initial slash and navigate within the application */
    return true;
  }
};

/**
 * Open a product URL based on provided ids
 */
const openProduct = ({
  categoryId,
  partnerId,
  productIdOrExternalCode
}: {
  categoryId?: string | number;
  partnerId?: string | number;
  productIdOrExternalCode?: string | number;
}) => {
  if (categoryId && partnerId && productIdOrExternalCode) {
    Router.push(`/categoria/${categoryId}/parceiro/${partnerId}/produto/${productIdOrExternalCode}`);
  } else if (categoryId && partnerId) {
    Router.push(`/categoria/${categoryId}/parceiro/${partnerId}`);
  } else if (categoryId) {
    Router.push(`/categoria/${categoryId}`);
  } else {
    Router.push(`/categorias`);
  }
};

const getFileTypeFromUrl = ({ fileName }: { fileName: string }) => {
  if (!fileName) {
    return "unknown";
  }

  const extension = fileName.split(".").pop()?.toLowerCase();

  const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp"];
  const videoExtensions = ["mp4", "webm", "ogg"];

  if (imageExtensions.includes(extension || "")) {
    return "image";
  } else if (videoExtensions.includes(extension || "")) {
    return "video";
  } else {
    return "unknown";
  }
};

/**
 * Converts a given text to a URL-friendly slug.
 *
 * This function normalizes the text by removing diacritics, converting it to lowercase,
 * replacing spaces with hyphens, removing non-alphanumeric characters (except hyphens),
 * and ensuring there are no consecutive hyphens.
 *
 * @param {string} text - The text to be converted to a slug.
 * @returns {string} - The URL-friendly slug.
 */
const generateSlugUrlFromText = (text: string) => {
  if (!text) {
    return "";
  }

  return text
    .normalize("NFD") // Normalize to decompose accented characters
    .replace(/[\u0300-\u036f]/g, "") // Remove diacritical marks
    .replace(/ç/g, "c") // Replace 'ç' with 'c'
    .replace(/[^a-zA-Z0-9\s-]/g, "") // Remove non-alphanumeric characters (except spaces and hyphens)
    .trim() // Remove whitespace from the start and end
    .replace(/\s+/g, "-") // Replace spaces with hyphens
    .replace(/-+/g, "-") // Remove duplicate hyphens
    .toLowerCase(); // Convert text to lowercase
};

export const LinkUtils = {
  openLink,
  openProduct,
  isInternalLink,
  getFileTypeFromUrl,
  generateSlugUrlFromText
};
