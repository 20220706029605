import { useRouter } from "next/router";

import { NextMuiLink } from "@/components/Link/NextMuiLink";
import theme from "@/styles/themes/theme";
import { Box, Tab, Tabs, Tooltip } from "@mui/material";
import { Question } from "@phosphor-icons/react";

import { styles } from "./styles";
import { MenuItemsProps } from "./types";

interface HeaderItemProps {
  menuItems: MenuItemsProps[];
}

export const HeaderItem: React.FC<HeaderItemProps> = ({ menuItems }) => {
  const { pathname } = useRouter();

  return (
    <Box sx={{ display: { xs: "none", sm: "inherit" } }}>
      <Tabs
        value={pathname}
        textColor="secondary"
        indicatorColor="secondary"
        variant="scrollable"
        scrollButtons="auto"
        css={styles.headeritem__tabs}
        sx={{ maxWidth: { sm: "50vw", md: "55vw", lg: "60vw", xl: "inherit" } }}
      >
        {menuItems?.map(({ title, url }) => (
          <Tab key={url} label={title} value={url} href={url} component={NextMuiLink} />
        ))}

        <Tab
          value="/ajuda"
          css={styles.headeritem__question}
          href="/ajuda"
          component={NextMuiLink}
          label={
            <Tooltip title="Ajuda">
              <Question size={24} color={theme.palette.common.black} />
            </Tooltip>
          }
        />

        <Tab value="/" style={{ minWidth: 0, minHeight: 0, padding: 0 }} />
      </Tabs>
    </Box>
  );
};
