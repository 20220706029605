import { useFormContext } from "react-hook-form";

import { SelectInput, TextInput } from "@/components/Inputs";
import { states } from "@/mocks/estados";
import { Grid, MenuItem, Typography } from "@mui/material";

export const LocateGasStation = () => {
  const { watch } = useFormContext();
  const stateStationGas = watch("favoriteStore.state");
  const nameFavoriteStore = watch("favoriteStore.name");
  const idFavoriteStore = watch("favoriteStore.id");
  const cityFavoriteStore = watch("favoriteStore.city");

  return (
    <>
      <Grid item xs={12}>
        <Typography fontSize={20} fontWeight={600}>
          Localize seu posto
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography fontSize={16} color="rgba(0, 0, 0, 0.87)">
          No Posto Favorito você ganha pontos KMV em dobro pagando com abastece-aí. Abasteça, compre na AmPm ou utilize os serviços Jet Oil.
        </Typography>
      </Grid>

      <Grid item xs={12} sm={12} md={4}>
        <TextInput
          id="favoriteStore.neighborhood"
          name="favoriteStore.neighborhood"
          label="Bairro"
          // helperText="Informe o bairro"
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <SelectInput id="favoriteStore.state" name="favoriteStore.state" label="Estado" placeholder="Selecione seu estado" defaultValue="" disabled>
          {states.map((state) => (
            <MenuItem key={state.sigla} value={state.sigla}>
              {state.nome}
            </MenuItem>
          ))}
        </SelectInput>
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <SelectInput id="favoriteStore.city" name="favoriteStore.city" label="Cidade" placeholder="Selecione sua cidade" defaultValue="" disabled>
          {stateStationGas &&
            states
              .find((item) => item.sigla === stateStationGas)
              ?.cidades.map((city) => (
                <MenuItem key={city} value={city}>
                  {city}
                </MenuItem>
              ))}
          <MenuItem value={cityFavoriteStore}>{cityFavoriteStore}</MenuItem>
        </SelectInput>
      </Grid>

      <Grid item xs={12} sm={12} md={12}>
        <SelectInput id="favoriteStore.id" name="favoriteStore.id" label="Posto favorito" placeholder="Selecione seu posto favorito" disabled>
          <MenuItem value={idFavoriteStore}>{nameFavoriteStore}</MenuItem>
          <MenuItem value=""></MenuItem>
        </SelectInput>
      </Grid>
    </>
  );
};
