import theme from "@/styles/themes/theme";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import { X } from "@phosphor-icons/react";

import { styles } from "./styles";
import { ModalProps } from "./types";

export default function Modal({ open, closeButton, onClose, title, children, buttons, action, dialogContentProps, ...props }: ModalProps) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} css={styles.modal} {...props}>
      <DialogTitle css={styles.modal__title}>
        <Typography variant="h6">{title}</Typography>

        {closeButton && (
          <IconButton size="large" aria-label="close" onClick={onClose}>
            <X color={theme.palette.common.black} />
          </IconButton>
        )}
      </DialogTitle>

      <DialogContent css={styles.modal__children} {...dialogContentProps}>
        {children}
      </DialogContent>

      <DialogActions css={styles.modal__title}>
        {action}

        {buttons ? (
          <Button variant="text" color="secondary" onClick={onClose}>
            <Typography variant="button" fontWeight={600}>
              {buttons}
            </Typography>
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
}
