/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useController, UseControllerProps } from "react-hook-form";

import { FormControl, FormHelperText, TextField, TextFieldProps } from "@mui/material";
import { DesktopDatePicker, DesktopDatePickerProps, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ptBR } from "date-fns/locale";

interface Props extends Partial<DesktopDatePickerProps<Date | string>>, UseControllerProps {
  name: string;
  required?: boolean;
  defaultValue?: Date | string | null; // Especifique os tipos possíveis para a propriedade defaultValue
  disabled?: boolean;
  label?: string;
  size?: "medium" | "small";
  variant?: TextFieldProps["variant"];
  disableFutureDates?: boolean; // Desabilita datas futuras, usada no blog
}

export const DateInput: React.FC<Props> = ({
  name,
  rules,
  shouldUnregister,
  defaultValue,
  control,
  required,
  label,
  size = "medium",
  variant,
  disableFutureDates = false, // Default value is false
  ...props
}) => {
  const { field, fieldState } = useController({
    name,
    rules,
    shouldUnregister,
    defaultValue,
    control
  });

  return (
    <FormControl error={Boolean(fieldState.error?.message)} fullWidth>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
        <DesktopDatePicker
          {...props}
          value={field.value}
          label={label}
          onChange={field.onChange}
          format="dd/MM/yyyy"
          maxDate={disableFutureDates ? new Date() : undefined}
          // slots={(params: TextFieldProps) => <TextField {...params} label={label} size={size} onBlur={field.onBlur} variant={variant} />}
        />
      </LocalizationProvider>

      {fieldState.error?.message ? <FormHelperText error={true}>{fieldState.error?.message}</FormHelperText> : null}
    </FormControl>
  );
};
