import axios, { AxiosResponse } from "axios";
import { useSession } from "next-auth/react";
import { useCallback, useEffect, useState } from "react";

import { useDisclose } from "@/hooks/utilHooks";
import theme from "@/styles/themes/theme";
import { Avatar, Badge, IconButton, ListItemAvatar, ListItemButton, ListItemText, Tooltip, useMediaQuery } from "@mui/material";
import { CaretDown, User } from "@phosphor-icons/react";

import { styles } from "./styles";
import { UserInfoBalance, UserInfoName } from "./UserMenu/UserInfo/UserInfo";
import { UserMenu } from "./UserMenu/UserMenu";

export const HeaderUserInfo: React.FC<{ closeCart: () => void }> = ({ closeCart }) => {
  const { isOpen, onToggle, onClose } = useDisclose();
  const isScreenSmall = useMediaQuery(() => theme.breakpoints.down("md"));
  const { data } = useSession();

  const [notification, setNotification] = useState<AxiosResponse>();

  const handleToggle = () => {
    onToggle();
    closeCart();
  };

  const callNotification = useCallback(async () => {
    setNotification(await axios.get("/api/notification"));
  }, []);

  useEffect(() => {
    callNotification();
  }, [callNotification]);

  if (!data?.user) {
    return null;
  }
  return (
    <UserMenu isOpen={isOpen} onClose={onClose} notification={notification}>
      <Tooltip title={isScreenSmall ? "Meu Perfil" : null}>
        <ListItemButton css={styles.headeruserinfo} onClick={handleToggle}>
          <ListItemAvatar css={styles.headeruserinfo__listitemavatar}>
            <Badge badgeContent={notification?.data ? "1" : null} color="secondary" overlap="circular">
              <Avatar>
                <User />
              </Avatar>
            </Badge>
          </ListItemAvatar>

          <ListItemText
            sx={{ display: { xs: "none", md: "block" } }}
            primary={<UserInfoName color="textPrimary" />}
            secondaryTypographyProps={{ component: "div" }}
            secondary={<UserInfoBalance />}
          />

          <IconButton disableRipple>
            <CaretDown />
          </IconButton>
        </ListItemButton>
      </Tooltip>
    </UserMenu>
  );
};
