import { useRouter } from "next/router";
import React from "react";

import { NextMuiLink } from "@/components/Link/NextMuiLink";
import { TextFieldSearch } from "@/components/Search/TextFieldSearch";
import { listPageBack } from "@/utils/listPageBack";
import { Button, Stack, Typography } from "@mui/material";
import { CaretLeft } from "@phosphor-icons/react";

import { Container } from "../../Container";
import { styles } from "./styles";

export const HeaderSearch: React.FC = () => {
  const router = useRouter();

  const currentRoute = router.pathname;
  const routeConfig = listPageBack.find((route) => route.currentRoute === currentRoute);

  return (
    <Stack width="100%" display="flex" flexDirection="row" bgcolor="#FFB206" position="relative" height="80px">
      {routeConfig?.hasBackButton && (
        <Button
          variant="text"
          startIcon={<CaretLeft size={24} color="#66550B" />}
          component={NextMuiLink}
          href={routeConfig.backRoute}
          css={styles.button}
          sx={{ paddingLeft: { xs: "28px", md: "50px" }, width: { xs: "110px", md: "200px" } }}
        >
          <Typography css={styles.typography}>{routeConfig.titleLabel}</Typography>
        </Button>
      )}

      <Container css={styles.container}>
        <TextFieldSearch />
      </Container>
    </Stack>
  );
};

export default HeaderSearch;
