/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosError } from "axios";
import { Session } from "next-auth";
/* eslint-disable @typescript-eslint/ban-types */
import { useSession } from "next-auth/react";
import { createContext, createRef, ReactNode, useContext, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import Login from "@/components/Login/Login";
import { LoginRef } from "@/components/Login/types";
import { OrderSummary } from "@/components/ShoppingCart/OrderSummary";
import { IAddItemToCart, ICartItem } from "@/components/ShoppingCart/types";
import { useToast } from "@/hooks/useToast";
import { IUseDisclose, useDisclose, useLoader } from "@/hooks/utilHooks";
import { ProductsServicesApi } from "@/services/api/productsServices.api";
import { ShoppingCartResponse } from "@/services/api/shoppingCartService.api";

type ShoppingCartProviderProps = {
  children: ReactNode;
};

export interface ShoppingCartContextProps {
  numberOfItemsInCart: number;
  totalPriceInCart: number;
  loading: boolean;
  cartDisclose: IUseDisclose;
  orderSummaryDisclose: IUseDisclose;
  cartItem: ICartItem | null;
  checkoutResponse: ShoppingCartResponse | null;
  removeItem: (itemId?: string | number) => void;
  checkout: () => void;
  closeOrderSummary: () => void;
  addItemToCart: (item: IAddItemToCart) => Promise<void>;
}

export const ShoppingCartContext = createContext<ShoppingCartContextProps>({} as ShoppingCartContextProps);

export const ShoppingCartProvider: React.FC<ShoppingCartProviderProps> = ({ children }) => {
  const { loading, showLoader, hideLoader } = useLoader();
  const orderSummaryDisclose = useDisclose();
  const cartDisclose = useDisclose();
  const loginModalRef = createRef<LoginRef>();
  const { status, data: session, update } = useSession();
  const { showToast } = useToast();
  const [cartItem, setCartItem] = useState<ICartItem | null>(null);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [checkoutResponse, setCheckoutResponse] = useState<ShoppingCartResponse | null>(null);

  const addItemToCart = async ({ categoryId, productId, partnerId }: IAddItemToCart) => {
    try {
      if (!categoryId || !productId || !partnerId) {
        throw new Error("O produto em questão não foi encontrado. Tente novamente.");
      }

      const { category, partner, product } = await ProductsServicesApi.getProductByIdWithCategoryAndPartner({ categoryId, partnerId, productId });

      if (!product?.id || !category?.categoryId || !partner?.partnerId) {
        throw new Error("O produto em questão não foi encontrado. Tente novamente.");
      }

      const cartData: ICartItem = {
        category,
        categoryId,
        partner,
        partnerId,
        product,
        productId,
        partnerLogo: partner?.partnerLogo,
        productDescription: product?.description,
        productTitle: product?.title,
        productExternalCode: product?.externalCode,
        productImage: product?.imageUrl,
        productKmPoints: product?.productCoinValue,
        promotionalValue: product?.promotionalValue
      };

      setCartItem(cartData);
      cartDisclose.onOpen();

      showToast({
        title: "Benefício adicionado ao seu carrinho!",
        description: "Você já pode pode concluir a troca, basta a seguir as próximas etapas.",
        severity: "success"
      });

      cartDisclose.onOpen();
    } catch (error) {
      const { message } = error as Error;

      showToast({
        severity: "error",
        title: "Não foi possível adicionar o item ao seu carrinho!",
        description: message
      });
    }
  };

  const removeItem = () => {
    showToast({ title: "Benefício removido do seu carrinho!", description: "Continue navegando, temos mais itens para você.", severity: "success" });
    setCartItem(null);
    cartDisclose.onClose();
  };

  const checkout = async () => {
    if (status !== "authenticated" || !session?.user) {
      return loginModalRef.current?.onOpen();
    }

    if (!executeRecaptcha) {
      throw new Error("Recaptcha não está pronto.");
    }

    try {
      showLoader();

      const recaptchaToken = await executeRecaptcha("login");

      const { token, user } = (await update()) as Session;

      const { data } = await axios.post("/api/checkout", {
        partnerId: cartItem?.partnerId,
        paymentMethodId: 1,
        termsAcceptance: true,
        pricingCode: 1,
        externalCode: cartItem?.productExternalCode,
        quantity: 1,
        dddTelefone: user?.basicInformations.mobilePhone?.areaCode ?? "",
        numeroTelefone: user?.basicInformations.mobilePhone?.number ?? "",
        codigoOperadora: "",
        recaptchaToken,
        token: token as string
      });

      try {
        if ((window as any).dataLayer) {
          (window as any).dataLayer.push({
            event: "productRedeem",
            productExternalCode: cartItem?.productExternalCode
          });
          (window as any).dataLayer.push({
            event: "partnerRedeem",
            partnerId: cartItem?.partnerId
          });
          console.log("Evento de productRedeem enviado para o Google Tag Manager", cartItem?.productExternalCode);
        }
      } catch (error) {
        console.error("Erro ao enviar o evento de productRedeem para o Google Tag Manager", error);
      }

      /* Refresh user balance */
      update();

      setCheckoutResponse({ ...data.checkout, attention: data.attention, instructions: data.instructions });

      orderSummaryDisclose.onOpen();

      cartDisclose.onClose();
    } catch (error) {
      const { response } = error as AxiosError<{ message: string }>;

      console.error(response?.data ?? error);

      showToast({
        severity: "error",
        title: "Não foi possível finalizar sua compra!",
        description: response?.data?.message,
        config: {
          duration: 8000 // 8 seconds
        }
      });
    } finally {
      hideLoader();
    }
  };

  const closeOrderSummary = () => {
    orderSummaryDisclose.onClose();
    setCartItem(null);
    cartDisclose.onClose();
  };

  return (
    <ShoppingCartContext.Provider
      value={{
        orderSummaryDisclose,
        cartDisclose,
        loading,
        totalPriceInCart: cartItem ? cartItem?.product?.productCoinValue : 0,
        numberOfItemsInCart: cartItem ? 1 : 0,
        checkoutResponse,
        cartItem,
        closeOrderSummary,
        checkout,
        addItemToCart,
        removeItem
      }}
    >
      {children}

      <Login ref={loginModalRef} />

      <OrderSummary isOpen={orderSummaryDisclose.isOpen} onClose={closeOrderSummary} />
    </ShoppingCartContext.Provider>
  );
};

export const useShoppingCart = () => {
  return useContext(ShoppingCartContext);
};
