import theme from "@/styles/themes/theme";
import { css } from "@emotion/react";

export const drawerWidth = 260;

export const styles = {
  modal: css`
    @media print {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 9999999999;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    & .MuiDialogContent-root {
      padding: 0px 0px;
    }
  `,
  modal__card: css`
    width: 100%;
    margin: 16px;
    overflow: auto;
  `,
  modal__card_logo: css`
    object-fit: contain;
    height: auto;
  `,
  modal__stack: css`
    width: 100%;
    padding: 16px;
    background-color: ${theme.palette.common.white};
  `,
  modal__stack_block: css`
    padding: 16px;
    border-radius: 16px;
    box-shadow: 0px 0px 0px 1px #e0e0e0;
    background-color: ${theme.palette.common.white};

    @media (max-width: 400px) {
      padding: 10px;
    }

    & li {
      margin-left: 16px;
    }
  `,
  modal__stack_block_cpf: css`
    padding: 16px;
    border-radius: 16px;
    justify-content: space-between;
    box-shadow: 0px 0px 0px 1px #e0e0e0;
    background-color: ${theme.palette.grey[200]};

    @media (max-width: 400px) {
      padding: 10px;
    }
  `,

  modal__avatar: css`
    background-color: ${theme.palette.warning.main};

    @media print {
      height: 18px;
    }
  `,
  modal__avatar_icon: css`
    size: 24px;
    color: ${theme.palette.common.white};

    @media print {
      color: ${theme.palette.warning.main};
    }
  `,
  modal__buttonCopy_noPrint: css`
    font-weight: 500;

    @media print {
      display: none;
    }
  `,
  modal__buttonPrint_noPrint: css`
    font-weight: 500;
    color: rgba(0, 0, 0, 0.87);

    @media print {
      display: none;
    }
  `
};
