import { useState } from "react";

export interface IUseDisclose {
  isOpen: boolean;
  onToggle: () => void;
  onOpen: () => void;
  onClose: () => void;
}

/**
 * Hook used to manage open/closed states
 * ex: modals or collapse
 *
 * @param {boolean} [open=false]
 * @return {*}  {IUseDisclose}
 */
export const useDisclose = (open = false): IUseDisclose => {
  const [isOpen, setIsOpen] = useState(open);

  const onToggle = () => setIsOpen((value) => !value);

  const onOpen = () => setIsOpen(true);

  const onClose = () => setIsOpen(false);

  return {
    isOpen,
    onToggle,
    onOpen,
    onClose
  };
};

interface IUseError {
  error: string;
  showError: (error: string | Error) => void;
  hideError: () => void;
}

/**
 * Hook used to manage hide/visible error states
 *
 * @param {string} [initialError='']
 * @return {*}  {IUseError}
 */
export const useError = (initialError = ""): IUseError => {
  const [error, setError] = useState(initialError);

  const showError = (newError: string | Error) => {
    if (newError instanceof Error) {
      return setError(newError.message);
    }

    setError(newError || "Não foi possível realizar a operação desejada");
  };

  const hideError = () => setError("");

  return {
    error,
    showError,
    hideError
  };
};

interface IUseLoading {
  loading: boolean;
  showLoader: () => void;
  hideLoader: () => void;
  toggleLoader: () => void;
}

/**
 * Hook used to manage hide/visible loading states
 *
 * @param {boolean} [initialState=false]
 * @return {*}  {IUseLoading}
 */
export const useLoader = (initialState = false): IUseLoading => {
  const [loading, setLoading] = useState(initialState);

  const showLoader = () => setLoading(true);

  const hideLoader = () => setLoading(false);

  const toggleLoader = () => setLoading(!loading);

  return {
    loading,
    showLoader,
    hideLoader,
    toggleLoader
  };
};

interface IUseSuccess {
  successMessage: string;
  showSuccess: (params: { message: string; timeout?: number }) => void;
  hideSuccess: () => void;
}
/**
 * Hook used to manage hide/visible success states
 *
 * @param {string} [initialMessage='']
 * @return {*}  {IUseSuccess}
 */
export const useSuccess = (initialMessage = ""): IUseSuccess => {
  const [successMessage, setSuccessMessage] = useState(initialMessage);

  const hideSuccess = () => setSuccessMessage("");

  const showSuccess = ({ message, timeout }: { message: string; timeout?: number }) => {
    setSuccessMessage(message);

    if (timeout) {
      setTimeout(() => hideSuccess(), timeout);
    }
  };

  return {
    successMessage,
    showSuccess,
    hideSuccess
  };
};
