import Image from "next/image";
import Link from "next/link";
import React from "react";

import LogoKMV from "@/assets/img/KMV_Logo_RGB_AzulTagline.png";
import { useDisclose } from "@/hooks/utilHooks";
import theme from "@/styles/themes/theme";
import { Document } from "@contentful/rich-text-types";
import { Container, Divider, Stack, Typography, useMediaQuery } from "@mui/material";

import { ModalKmSimulator } from "../ModalKmSimulator";
import { styles } from "./styles";
import { LinkFooterProps } from "./types";

const LinkFooter: React.FC<LinkFooterProps> = ({ text, href, newTab, ...props }) => {
  return (
    <Typography variant="body2" {...props}>
      <Link href={href} passHref aria-label={text} target={newTab ? "_blank" : "_self"} css={styles.container__link}>
        {text}
      </Link>
    </Typography>
  );
};

type FooterProps = {
  simulatorAccumulationInformativeText: Document;
  locationCompany?: string;
};

export const Footer: React.FC<FooterProps> = ({ simulatorAccumulationInformativeText, locationCompany }) => {
  const simulatorModal = useDisclose();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const content = [
    { text: "Como funciona", link: "/como-funciona" },
    { text: "Fale conosco", link: "/fale-conosco" },
    { text: "Ajuda", link: "/ajuda" }
  ];

  return (
    <>
      <footer
        style={{
          bottom: 0,
          width: "100%"
        }}
      >
        <Container maxWidth={false} css={styles.container}>
          <Stack direction={{ md: "column", lg: "row" }} py={2} justifyContent="center" alignItems="center" gap={2}>
            <Stack direction={"row"} justifyContent="center" alignItems="center" gap={2}>
              <Image src={LogoKMV} alt="Logo KMV" height={isSmallScreen ? 45 : 40} />
            </Stack>

            <Typography onClick={() => simulatorModal.onOpen()} variant="body2" sx={{ cursor: "pointer" }}>
              Simule como ganhar pontos KMV
            </Typography>

            {content.map((link) => (
              <LinkFooter key={link.text} text={link.text} href={link.link} />
            ))}
          </Stack>

          <Divider css={styles.container__divider} />

          <Stack
            pt={2}
            spacing={1}
            direction={{ xs: "column", md: "row" }}
            alignItems="center"
            justifyContent="center"
            divider={<Divider sx={{ border: "1px solid #000" }} />}
            css={styles.container__typography_superior}
          >
            <LinkFooter text="Proteção de Dados Pessoais" href="/privacidade-protecao-dados" fontSize={12} />

            <LinkFooter text="Regulamento" href="/regulamento" fontSize={12} />
            <LinkFooter text="Institucional" href="/institucional" fontSize={12} />
          </Stack>

          <Typography py={2} fontSize={12} gutterBottom={false} whiteSpace="pre-wrap" textAlign="center" css={styles.container__typography}>
            © {new Date().getFullYear()} - KMV, programa de fidelidade dos postos Ipiranga.
            <br />
            Uma empresa do Grupo Ultra. {locationCompany}
          </Typography>
        </Container>
      </footer>

      <ModalKmSimulator
        isOpen={simulatorModal.isOpen}
        onClose={simulatorModal.onClose}
        simulatorAccumulationInformativeText={simulatorAccumulationInformativeText}
      />
    </>
  );
};
