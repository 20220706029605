/* eslint-disable react/no-unknown-property */
import { MouseEvent, useState } from "react";

import theme from "@/styles/themes/theme";
import { Stack, ToggleButton, ToggleButtonGroup, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { Calendar, CalendarBlank, Info } from "@phosphor-icons/react";

import { styles } from "./styles";

export const ResultsSimulation = ({ value }: { value: number }) => {
  const [period, setPeriod] = useState("month");
  const isDownSm = useMediaQuery(theme.breakpoints.down("sm"));

  const handlePeriod = (event: MouseEvent<HTMLElement>, newPeriod: string | null) => {
    if (newPeriod != null) {
      setPeriod(newPeriod);
    }
  };

  return (
    <Stack
      spacing={2}
      rowGap={2}
      direction={{ sm: "row", md: "column", lg: "column" }}
      justifyContent="center"
      alignItems="center"
      width="50%"
      css={styles.modal__stack_block}
    >
      <ToggleButtonGroup
        exclusive
        value={period}
        onChange={handlePeriod}
        orientation={isDownSm ? "vertical" : "horizontal"}
        css={styles.modal__toggleButton}
      >
        <ToggleButton value="month">
          <CalendarBlank size={24} color={theme.palette.secondary.main} />
          <Typography fontSize={15} fontWeight={500} color={theme.palette.secondary.main} ml={1}>
            No MÊS
          </Typography>
        </ToggleButton>

        <ToggleButton value="year">
          <Calendar size={24} />
          <Typography fontSize={15} fontWeight={500} color={theme.palette.secondary.main} ml={1}>
            Acumulado no Ano
          </Typography>
        </ToggleButton>
      </ToggleButtonGroup>

      <Stack direction="row" width={{ sm: "50%", md: "100%", lg: "100%" }} alignSelf="normal">
        <Stack width="92%" justifyContent="center" alignItems="center" textAlign="center">
          <Typography fontSize={24} fontWeight={500} color={theme.palette.secondary.main}>
            Pontos KMV acumulados* <br />
            <Typography component="span" css={styles.modal__typography_km} fontSize={{ xs: 16, sm: 24, md: 38, lg: 48 }}>
              {period === "month" ? value : value * 12} PONTOS KMV
            </Typography>
          </Typography>
        </Stack>
        <Tooltip
          title="Esta é a quantidade de pontos KMV que você ganha quando consume o valor e as condições indicadas na simulação. Confira os benefícios que você pode obter com seus pontos KMV!"
          placement="top"
          arrow
        >
          <Info size={24} color="rgba(0, 0, 0, 0.54)" />
        </Tooltip>
      </Stack>
    </Stack>
  );
};
