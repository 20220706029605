import axios, { AxiosError } from "axios";

import { config } from "@/config";
import { Document } from "@contentful/rich-text-types";

import { CategoryConfig, PartnerConfig } from "../contentful/categories";

/* axiosRetry(axios, { retries: 3 }); */

const API_URL = `${config.gatewayApiUrl}/kmv/shop/partners`;

interface ProductApiResponse {
  id: number;
  externalCode: string;
  promotionalMessage: string;
  productImage: string;
  description: string;
  redemptionUrl: string;
  productCoinValue: number;
  promotionalBrlValue: number;
  partnerCoinBonus: number;
  eventDate: unknown;
  portalDescription: string;
  lastRedemption: unknown;
  generalExchageQuantity: number;
  availableQuantity: number;
  availablePrestigeQuantity: number;
  maximumRedemptionQuantity: number;
  redeemable: boolean;
  message: unknown;
  availableVoucherQuantity: number;
  participantBalance: number;
  DescriptionPromotionalParcelled: unknown;
  bonusKm: unknown;
  categories: Array<{ id: number; description: string }>;
}

interface ProductsApiResponse {
  sucess: boolean;
  message: string;
  errorCode: string;
  validationError: unknown;
  products: ProductApiResponse[];
}

export interface ProductItem {
  id: number | string;
  partnerId: number | string /* Added type to make a relationship between product and partner */;
  categoryId: number | string /* Added type to make a relationship between product and category */;
  productId: number | string /* Virtual property equals to id */;
  title: string /* Added type to make a title from category name */;
  externalCode: string;
  promotionalMessage: string;
  imageUrl: string;
  description: string;
  redemptionUrl: string;
  productCoinValue: number;
  productCoinValueText: string;
  promotionalValue: number;
  partnerBonusCurrency: number;
  eventDate: null | string;
  portalDescription: string;
  lastRedemption: null | string;
  remainingExchangeQuantityOverall: number;
  availableQuantity: number;
  availableQuantityPrestige: number;
  maxRedemptionQuantity: number;
  canRedeem: boolean;
  message: null | string;
  availableVoucherQuantity: number;
  participantBalance: number;
  mobileTitle: null | string;
  mobileOrder: null | string;
  mobileColor: null | string;
  promotionalDescriptionInstallment: null | string;
  partnerBonusKm: null | string;
  categories: Array<{
    id: number;
    description: string;
  }>;
  tags: string[];
  itemSwapInstructions: Document;
  isInformative?: boolean;
  buttonTitle?: string;
}

const transformProductResponse = (product: ProductApiResponse, partnerId: number | string, categoryId: number | string): ProductItem => {
  return {
    id: product.id,
    productId: product.id,
    categoryId,
    partnerId,
    externalCode: product.externalCode,
    promotionalMessage: product.promotionalMessage,
    imageUrl: product.productImage,
    description: product.description,
    redemptionUrl: "",
    productCoinValue: product.productCoinValue,
    productCoinValueText: `${product.productCoinValue} pontos KMV`,
    promotionalValue: product.promotionalBrlValue,
    partnerBonusCurrency: product.partnerCoinBonus,
    eventDate: product.eventDate,
    portalDescription: product.portalDescription,
    lastRedemption: product.lastRedemption,
    remainingExchangeQuantityOverall: product.generalExchageQuantity,
    availableQuantity: product.availableQuantity,
    availableQuantityPrestige: product.availablePrestigeQuantity,
    maxRedemptionQuantity: product.maximumRedemptionQuantity,
    canRedeem: product.redeemable,
    message: product.message,
    availableVoucherQuantity: product.availableVoucherQuantity,
    participantBalance: product.participantBalance,
    promotionalDescriptionInstallment: product.DescriptionPromotionalParcelled,
    partnerBonusKm: product.bonusKm,
    categories: [] as ProductItem["categories"] /* Disabled to prevent show wrong old categories: product.categories ??  */
  } as ProductItem;
};

/**
 * List all products by partnerId
 */
const listProducts = async ({ partnerId, categoryId }: { partnerId: number | string; categoryId: number | string }) => {
  try {
    if (isNaN(partnerId as number)) {
      return [];
    }
    const { data } = await axios.get<ProductsApiResponse>(`${API_URL}/${partnerId}/products`);

    return data.products.map((product) => transformProductResponse(product, partnerId, categoryId));
  } catch (error) {
    const { message } = error as Error;
    console.error("Error", message);
    return [];
  }
};

/**
 * List all products by partnerIds
 */
const listProductsByPartnerIds = async ({ partnerIds, categoryId }: { partnerIds: number[] | string[]; categoryId: number | string }) => {
  try {
    const promises = await Promise.allSettled(partnerIds.map((partnerId) => ProductsServicesApi.listProducts({ partnerId, categoryId })));
    /* Get only resolved promises */
    const products = (promises.filter(({ status }) => status === "fulfilled") as PromiseFulfilledResult<ProductItem[]>[]).flatMap(
      ({ value }) => value
    );

    return products;
  } catch (error) {
    const { message } = error as Error;
    console.error("Error", message);
    return [];
  }
};

/**
 * Get a product by id or externalCode
 */
const getProductById = async ({
  partnerId,
  productId,
  categoryId
}: {
  partnerId: number | string;
  productId: number | string;
  categoryId: number | string;
}) => {
  try {
    const products = await listProducts({ categoryId, partnerId });
    const product = products.find((item) => item.externalCode === String(productId) || item.id === Number(productId));

    if (!product) {
      throw new Error("Produto não encontrado.");
    }

    return product;
  } catch (error) {
    const { message } = error as Error;
    console.error("Error", message);
    return {} as ProductItem;
  }
};

/**
 * Get a product by id returning partner and category
 */
const getProductByIdWithCategoryAndPartner = async ({
  partnerId,
  productId,
  categoryId
}: {
  categoryId: number | string;
  partnerId: number | string;
  productId: number | string;
}) => {
  try {
    const {
      data: { category, partner, product }
    } = await axios.get<{ category: CategoryConfig; partner: PartnerConfig; product: ProductItem }>("/api/products/get-product-by-id", {
      params: { productId, categoryId, partnerId }
    });

    return { product, partner, category };
  } catch (error) {
    const { response, message } = error as AxiosError<{ message: string }>;
    console.error("Error", message);

    throw new Error(response?.data?.message || "Tente novamente em alguns instantes.");
  }
};

export const ProductsServicesApi = { listProducts, getProductById, listProductsByPartnerIds, getProductByIdWithCategoryAndPartner };
