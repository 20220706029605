import { css } from "@emotion/react";

export const styles = {
  stack_img: css`
    align-self: end;
    align-items: center;

    @media (max-width: 899px) {
      align-items: end;
      align-self: center;
      width: 60%;
    }

    @media (max-width: 599px) {
      align-items: center;
      width: 70%;
    }
  `,
  img: css`
    width: 50%;
    height: auto;
    margin-top: 20px;
    margin-left: 20px;
    transform: rotate(-10deg);

    @media (max-width: 899px) {
      width: 80%;
      height: auto;
    }

    @media (max-width: 599px) {
      /* display: none; */
      width: 80%;
      margin-left: 0px;
    }
  `,
  stack_download: css`
    background: linear-gradient(180deg, #0087cc 0%, #006092 100%);
  `
};
