import Image from "next/image";
import React from "react";

import theme from "@/styles/themes/theme";
import { Button, CardActions, CardContent, Container, Divider, Stack, Typography } from "@mui/material";
import { Trash } from "@phosphor-icons/react";

import { styles } from "./styles";
import { ShoppingCartItemProps } from "./types";

export const ShoppingCartItem: React.FC<ShoppingCartItemProps> = ({ id, title, logo, kmPrice, quantity, removerItem }) => {
  return (
    <Container maxWidth="xs" sx={{ pl: "0px !important", pr: "0px !important" }}>
      <CardContent sx={{ pb: "0px !important" }}>
        <Stack borderRadius={1} boxShadow=" 0px 0px 0px 1px #E0E0E0">
          <Stack direction={{ xs: "column", sm: "row", md: "row" }} p={2} spacing={1}>
            <Image
              src={logo || "/placeholder.png"}
              alt="Imagem do Produto"
              width={100}
              height={100}
              style={{ borderRadius: "8px", objectFit: "contain" }}
            />

            <Stack spacing={1} alignSelf="center">
              <Typography fontSize={14} color={theme.palette.secondary.main}>
                {title}
              </Typography>

              <Typography fontSize={14} fontWeight={700} color={theme.palette.text.secondary}>
                Quantidade: <span style={{ fontWeight: 400 }}> {quantity.toString().padStart(2, "0")} </span>
              </Typography>

              <Typography fontSize={14} fontWeight={600} color={theme.palette.warning.main}>
                {isNaN(kmPrice) ? 0 : kmPrice} pontos KMV
              </Typography>
            </Stack>
          </Stack>

          <Divider css={styles.drawer__divider} />
          <CardActions sx={{ paddingBottom: "16px" }}>
            <Button color="warning" onClick={() => removerItem(id)} sx={{ fontWeight: 600, gap: 1, ml: 1, pr: 2 }}>
              <Trash size={20} color={theme.palette.warning.main} />
              Remover
            </Button>
          </CardActions>
        </Stack>
      </CardContent>
    </Container>
  );
};
