import { css } from "@emotion/react";

export const styles = {
  stack_img: css`
    @media (max-width: 899px) {
      align-self: center;
      align-items: center;
      width: 20%;
    }
  `,
  img: css`
    width: 14rem;
    height: auto;
    margin-top: 20px;
    transform: rotate(-10deg);

    @media (max-width: 899px) {
      width: 14rem;
      height: auto;
    }

    @media (max-width: 599px) {
      /* display: none; */
      width: 100%;
    }
  `
};
