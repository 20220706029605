import { MaskedInput, TextInput } from "@/components/Inputs";
import { Grid, Typography } from "@mui/material";

export const AccountData = () => {
  return (
    <>
      <Grid item xs={12}>
        <Typography fontSize={20} fontWeight={600}>
          Dados da Conta
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <TextInput fullWidth id="email" name="email" label="E-mail" disabled />
      </Grid>

      <Grid item xs={12} sm={6} md={6}>
        <MaskedInput
          fullWidth
          name="mobilePhone"
          label="Telefone Celular"
          placeholder="(00) 00000 0000"
          // helperText="Informe somente os números com DDD"
          mask="(##) #####-####"
          disabled
        />
      </Grid>

      <Grid item xs={12} sm={6} md={6}>
        <MaskedInput
          fullWidth
          name="homePhone"
          label="Telefone fixo (opcional)"
          placeholder="(00) 0 0000 0000"
          // helperText="Informe somente os números com DDD"
          mask="(##) ####-####"
          disabled
        />
      </Grid>

      {/* <Grid item xs={12} sm={6} md={6}>
        <PasswordStrength />
      </Grid> */}
    </>
  );
};
