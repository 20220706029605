export default {
  PRIMARY_MAIN: "#FFD41C",
  PRIMARY_LIGHT: "#FFDC49",
  PRIMARY_DARK: "#B29413",

  SECONDARY_MAIN: "#003863",
  SECONDARY_LIGHT: "#335F82",
  SECONDARY_DARK: "#002745",

  ERROR_MAIN: "#D14E1D",
  ERROR_LIGHT: "#DA714A",
  ERROR_DARK: "#923614",

  WARNING_MAIN: "#FF5800",
  WARNING_LIGHT: "#FF7933",
  WARNING_DARK: "#B23D00",

  INFO_MAIN: "#0089CF",
  INFO_LIGHT: "#33A0D8",
  INFO_DARK: "#005F90",

  SUCCESS_MAIN: "#6CC04A",
  SUCCESS_LIGHT: "#89CC6E",
  SUCCESS_DARK: "#4B8633",

  WHITE: "#FFFFFF",
  GRAY_100: "#F5F5F5",
  GRAY_200: "#EEEEEE",
  GRAY_300: "#e0e0e0",
  GRAY_400: "#ACB0B3",
  GRAY_500: "",
  GRAY_600: "#0C2432",
  GRAY_700: "#818181",
  GRAY_800: "#0C2432",
  GRAY_900: "",
  BLACK: "#000000"
};
