import React, { ReactNode, useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import theme from "@/styles/themes/theme";
import { passwordMatch } from "@/utils/passwordMatch";
import { css } from "@emotion/react";
import { Stack, Typography } from "@mui/material";
import { CheckCircle } from "@phosphor-icons/react";

const styles = {
  icon: css`
    @media (max-width: 600px) {
      size: 20px;
    }
  `
};

export const PasswordStrength: React.FC = () => {
  const { control } = useFormContext();
  const password = useWatch({ control, name: "password" });

  const { hasValidLength, hasLetterAndNumber } = useMemo(() => {
    return passwordMatch(password);
  }, [password]);

  return (
    <Stack spacing={2}>
      <PasswordMatchRule valid={hasValidLength}>Sua senha deve possuir entre 8 - 16 caracteres</PasswordMatchRule>
      <PasswordMatchRule valid={hasLetterAndNumber}>Sua senha deve conter pelo menos uma letra e um número</PasswordMatchRule>
    </Stack>
  );
};

const PasswordMatchRule: React.FC<{ children: ReactNode; valid: boolean }> = ({ children, valid }) => {
  return (
    <Stack spacing={1} direction="row" alignItems="center">
      <CheckCircle color={valid ? theme.palette.success.main : "#757575"} size={24} css={styles.icon} />
      <Typography color={theme.palette.text.secondary} fontSize={{ xs: 12, sm: 14, md: 14 }}>
        {children}
      </Typography>
    </Stack>
  );
};
