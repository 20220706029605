import Image from "next/image";

import ForgotPassword from "@/assets/img/app_kmv_home.webp";
import theme from "@/styles/themes/theme";
import { Stack, Typography } from "@mui/material";

import Modal from "../Modal/Modal";
import { styles } from "./styles";
import { ForgotPasswordModalProps } from "./types";

export const ForgotPasswordModal: React.FC<ForgotPasswordModalProps> = ({ isOpen, onClose }) => {
  return (
    <>
      <Modal
        open={isOpen}
        onClose={onClose}
        maxWidth="md"
        title="Esqueceu sua senha?"
        buttons="Fechar"
        dialogContentProps={{ sx: { bgcolor: theme.palette.common.white, paddingBottom: 0, px: 0 } }}
      >
        <Stack spacing={4} width="100%" direction="column">
          <Stack px={2} spacing={4} display="flex" flexGrow={1} direction={{ xs: "column", sm: "row", md: "row" }}>
            <Stack display="flex" flexGrow={1} alignSelf="end" alignItems="end" css={styles.stack_img}>
              <Image src={ForgotPassword} alt="" css={styles.img} />
            </Stack>

            <Stack boxShadow={0} display="flex" flexGrow={1} spacing={0} alignSelf="center" direction="column">
              <Typography fontSize={{ xs: 24, sm: 30, md: 34 }} fontWeight={700} color={theme.palette.secondary.dark}>
                Esqueceu a senha?
              </Typography>
              <Typography fontSize={{ xs: 24, sm: 30, md: 34 }} fontWeight={700} color={theme.palette.info.main}>
                Não tem problema.
              </Typography>
            </Stack>
          </Stack>

          <Stack alignItems="center" bgcolor={theme.palette.info.main} px={2}>
            <Typography my={8} fontSize={{ xs: 20, sm: 20, md: 24 }} color={theme.palette.common.white}>
              Acesse agora seu app <span style={{ fontWeight: 900 }}>KMV</span> e clique em “Esqueci minha senha”
            </Typography>
          </Stack>
        </Stack>
      </Modal>
    </>
  );
};
