import theme from "@/styles/themes/theme";
import { css } from "@emotion/react";

export const drawerWidth = 260;

export const styles = {
  modal__stack_block: css`
    width: 51%;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0px 0px 0px 1px #e0e0e0;
    background-color: ${theme.palette.common.white};

    @media (max-width: 900px) {
      width: 100%;
    }
  `,
  modal__divider: css`
    border: 1px solid rgba(0, 0, 0, 0.12);
  `,
  modal__slider: css`
    color: ${theme.palette.secondary.main};
    height: 6px;
    margin-top: 24px;

    .MuiSlider-track {
      border: none;
    }
    .MuiSlider-thumb {
      width: 20px;
      height: 20px;
      z-index: 99999;
      position: absolute;
      border: 4px solid ${theme.palette.info.main};
      background-color: ${theme.palette.primary.main};

      &:focus,
      &:hover,
      &.Mui-active,
      &.Mui-focusVisible {
        box-shadow: inherit;
      }
      &:before {
        display: none;
      }
    }
    .MuiSlider-valueLabel {
      font-size: 14px;
      font-weight: 500;
      line-height: 157%;

      height: 34px;
      padding-top: 4px;
      padding-bottom: 4px;
      padding-left: 12px;
      padding-right: 12px;
      border-radius: 6px;
      background-color: rgba(117, 117, 117, 1);
      transform-origin: bottom left;
    }
  `,
  modal__toggleButton: css`
    .MuiToggleButtonGroup-grouped {
      border: 1px solid var(--light-other-divider, rgba(0, 0, 0, 0.12));
      border-radius: 4px;
    }
  `
};
