/* eslint-disable @typescript-eslint/no-unused-vars */
import { useController, UseControllerProps } from "react-hook-form";

import { FormControl, FormControlProps, FormHelperText, InputLabel, Select, SelectProps, styled } from "@mui/material";

interface Props extends SelectProps, UseControllerProps {
  name: string;
  defaultValue?: string | number;
  size?: "medium" | "small";
  formControlProps?: FormControlProps;
}

export const SelectInput: React.FC<Props> = ({
  name,
  rules,
  shouldUnregister,
  defaultValue,
  control,
  label,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  variant = "outlined",
  size = "medium",
  formControlProps,
  fullWidth = true,
  ...props
}) => {
  const { field, fieldState } = useController({
    name,
    rules,
    shouldUnregister,
    defaultValue,
    control
  });

  return (
    <FormControl fullWidth={fullWidth} error={Boolean(fieldState.error?.message)} required={props.required} size={size} {...formControlProps}>
      <InputLabel id={name}>{label}</InputLabel>
      <Select labelId={name} value={field.value ?? ""} onChange={field.onChange} label={label} {...props} />
      <FormHelperText>{fieldState.error?.message}</FormHelperText>
    </FormControl>
  );
};

export const UnstyledSelectInput = styled(SelectInput)(({ theme }) => ({
  "& fieldset": {
    border: "none"
  }
}));
