import axios from "axios";
import { useSession } from "next-auth/react";
import { useCallback, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";

import Modal from "@/components/Modal/Modal";
import { RegisterApiResponse } from "@/pages/api/profile-data";
import theme from "@/styles/themes/theme";
import { Card, CardContent, Divider, Grid, Typography } from "@mui/material";

import { AccountData } from "./AccountData";
import { Address } from "./Address";
import { BelovedTeam } from "./BelovedTeam";
import { LocateGasStation } from "./LocateGasStation";
import { PersonalData } from "./PersonalData";
import { styles } from "./styles";
import { Terms } from "./Terms";
import { MyRegistrationProps } from "./types";
import { Session } from "next-auth";

export const MyRegistration: React.FC<MyRegistrationProps> = ({ isOpen, onClose }) => {
  const form = useForm();
  const { update } = useSession();

  const profileData = useCallback(async () => {
    if (isOpen === true) {
      try {
        const { token } = (await update()) as Session;

        const { data } = await axios.get<RegisterApiResponse>("/api/profile-data", { headers: { Authorization: token } });

        form.reset({
          ...data,
          birthDate: data.birthDate ? new Date(data.birthDate) : null,
          fullName: data.name && data.surname ? `${data?.name} ${data?.surname}` : "",
          mobilePhone: `${data?.mobilePhone?.areaCode}${data?.mobilePhone?.number}` || "",
          homePhone: `${data?.homePhone?.areaCode}${data?.homePhone?.number}` || ""
        });
      } catch (error) {
        /* empty */
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, isOpen]);

  useEffect(() => {
    profileData();
  }, [profileData]);

  return (
    <Modal open={isOpen} onClose={onClose} title="Meu cadastro" maxWidth="md" buttons="Fechar">
      <Card css={styles.modal__card}>
        <CardContent sx={{ bgcolor: theme.palette.common.white }}>
          <Typography maxWidth={368} color={theme.palette.grey[500]}>
            Os dados opcionais servem para melhor segmentação de ofertas
          </Typography>
          <FormProvider {...form}>
            <Grid container spacing={3} mt={1}>
              <PersonalData />

              <AccountData />

              <Address />

              <LocateGasStation />

              <BelovedTeam />

              <Grid item xs={12}>
                <Divider sx={{ border: "1px solid rgba(0, 0, 0, 0.12)" }} />
              </Grid>

              <Terms />
            </Grid>
          </FormProvider>
        </CardContent>
      </Card>
    </Modal>
  );
};
