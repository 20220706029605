import { css } from "@emotion/react";

export const styles = {
  headeruserinfo: css`
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;

    @media (max-width: 900px) {
      gap: 5px;
    }
  `,
  headeruserinfo__listitemavatar: css`
    @media (max-width: 900px) {
      min-width: 40px;
    }
  `
};
