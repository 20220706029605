import { css } from "@emotion/react";

export const styles = {
  typography: css`
    font-size: 16px;
    font-weight: 400;
    text-transform: none;
    color: #66550b;
  `,
  button: css`
    z-index: 1;
    position: absolute;
    height: 100%;

    @media (max-width: 900px) {
      position: relative;
    }
  `,
  container: css`
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 16px;
    margin-bottom: 16px;
    padding-top: 0px;
    padding-bottom: 0px;
  `
};
