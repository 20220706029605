import React from "react";

import { Footer } from "@/components/Footer/Footer";
import { MenuItemsProps } from "@/components/Header/HeaderItem/types";
import { Menu } from "@/components/Menu";
import { Document } from "@contentful/rich-text-types";
import styled from "@emotion/styled";

const Wrapper = styled.div`
  box-sizing: border-box;
  margin-top: 136px;
  flex-grow: 1;
`;

const Main = styled.main`
  flex: 1;
`;

type LayoutDefaultProps = {
  children?: JSX.Element;
  menuItems: MenuItemsProps[];
  simulatorAccumulationInformativeText: Document;
  locationCompany?: string;
};

export const LayoutDefault: React.FC<LayoutDefaultProps> = ({ children, menuItems, simulatorAccumulationInformativeText, locationCompany }) => {
  return (
    <>
      <Menu menuItems={menuItems} />
      <Wrapper>
        <Main>{children}</Main>
      </Wrapper>
      <Footer simulatorAccumulationInformativeText={simulatorAccumulationInformativeText} locationCompany={locationCompany} />
    </>
  );
};
