/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from "react";
import { useController, useFormContext } from "react-hook-form";
import { PatternFormat } from "react-number-format";

import { ITextInputProps, TextInput } from "./TextInput";

interface Props extends ITextInputProps {
  mask: string;
  triggerFieldOnChange?: string;
}

export const MaskedInput: React.FC<Props> = ({
  placeholder,
  mask,
  name,
  rules,
  shouldUnregister,
  defaultValue,
  control,
  triggerFieldOnChange,
  ...props
}) => {
  const { trigger } = useFormContext();

  const { field } = useController({
    name,
    rules,
    shouldUnregister,
    defaultValue,
    control
  });

  const handleChange = ({ value }: { value: string }) => {
    field.onChange(value);

    if (triggerFieldOnChange) {
      trigger(triggerFieldOnChange);
    }
  };

  return (
    // @ts-ignore
    <PatternFormat
      customInput={TextInput}
      variant="outlined"
      fullWidth
      autoComplete="off"
      placeholder={placeholder}
      format={mask}
      name={name}
      rules={rules}
      shouldUnregister={shouldUnregister}
      defaultValue={defaultValue}
      control={control}
      onValueChange={handleChange}
      // @ts-ignore
      value={typeof field.value === "string" ? field.value : ""}
      // value={field.value}
      onBlur={field.onBlur}
      {...props}
    />
  );
};
