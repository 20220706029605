import { format } from "date-fns";
import { useSession } from "next-auth/react";

import { Typography } from "@mui/material";

export const UserInfoName = ({ color }: { color?: string }) => {
  const { data } = useSession();

  if (!data?.user) {
    return null;
  }

  const { name } = data.user;
  return (
    <Typography variant="body2" color={color} textTransform="none">
      {`Olá, ${name}`}
    </Typography>
  );
};

export const UserInfoBalance = () => {
  const { data } = useSession();

  if (!data?.user) {
    return null;
  }

  const { balance } = data.user;

  return (
    <>
      <Typography fontSize={12} color="textSecondary" textTransform="none">
        Você tem:{" "}
        <Typography component="span" fontWeight="600" fontSize={12}>
          {balance?.balance ?? 0} pontos KMV
        </Typography>
      </Typography>

      {balance?.expiresDate ? (
        <Typography fontSize={11} color="textSecondary" textTransform="none">
          {`Expira em ${format(new Date(balance.expiresDate), "dd/MM")}: ${balance?.expiresBalance || 0} pontos KMV`}
        </Typography>
      ) : null}
    </>
  );
};
