import { useFormContext } from "react-hook-form";

import { SelectInput, TextInput } from "@/components/Inputs";
import { states } from "@/mocks/estados";
import { Grid, MenuItem, Typography } from "@mui/material";

export const Address = () => {
  const { watch } = useFormContext();
  const state = watch("address.state");

  return (
    <>
      <Grid item xs={12}>
        <Typography fontSize={20} fontWeight={600}>
          Seu endereço
        </Typography>
      </Grid>

      <Grid item xs={12} sm={12} md={4}>
        <TextInput
          id="address.zipCode"
          name="address.zipCode"
          label="CEP"
          placeholder="Informe o seu CEP"
          // helperText="Informe somente os números"
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={12} md={8}>
        <TextInput
          id="address.street"
          name="address.street"
          label="Endereço"
          placeholder="Rua, av..."
          // helperText="Informe sua rua, avenida"
          disabled
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <TextInput
          id="address.number"
          name="address.number"
          label="Número"
          // helperText="Informe o número"
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={6} md={8}>
        <TextInput id="address.complement" name="address.complement" label="Complemento" disabled />
      </Grid>

      <Grid item xs={12} sm={12} md={4}>
        <TextInput
          id="address.neighborhood"
          name="address.neighborhood"
          label="Bairro"
          // helperText="Informe o bairro"
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <SelectInput
          id="address.state"
          name="address.state"
          label="Estado"
          // placeholder="Selecione seu estado"
          defaultValue=""
          disabled
        >
          {states.map((state) => (
            <MenuItem key={state.sigla} value={state.sigla}>
              {state.nome}
            </MenuItem>
          ))}
        </SelectInput>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <SelectInput
          id="address.city"
          name="address.city"
          label="Cidade"
          // placeholder="Selecione sua cidade"
          defaultValue=""
          disabled
        >
          {state &&
            states
              .find((item) => item.sigla === state)
              ?.cidades.map((city) => (
                <MenuItem key={city} value={city}>
                  {city}
                </MenuItem>
              ))}
        </SelectInput>
      </Grid>
    </>
  );
};
