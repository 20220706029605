/* eslint-disable @next/next/no-img-element */
import axios from "axios";
import Image from "next/image";
import Link from "next/link";
import React, { useEffect, useState } from "react";

import LogoKMV from "@/assets/img/KMV_Logo_RGB_AzulTagline.png";
import { useDisclose } from "@/hooks/utilHooks";
import { CategoryConfig } from "@/services/contentful/categories";
import theme from "@/styles/themes/theme";
import { Divider, Grid, ListItem, ListItemButton, ListItemText, Stack, Tooltip } from "@mui/material";
import { Drawer as MuiMenu } from "@mui/material/";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import { styled } from "@mui/material/styles";
import { CaretLeft } from "@phosphor-icons/react";

import Header from "../Header/Header";
import { MenuItemsProps } from "../Header/HeaderItem/types";
import { NextMuiLink } from "../Link/NextMuiLink";
import { ItemMenu } from "./ItemMenu";
import { styles } from "./styles";

const StyledIcon = styled(CaretLeft)({
  fontSize: 28,
  cursor: "pointer",
  color: "#003863",
  opacity: 0.7,
  transition: "opacity 0.2s ease-in-out",
  "&:hover": {
    opacity: 1
  }
});

interface MenuProps {
  menuItems: MenuItemsProps[];
}

export const Menu: React.FC<MenuProps> = ({ menuItems }) => {
  const { isOpen, onToggle, onClose } = useDisclose();
  const [categories, setCategories] = useState<CategoryConfig[]>([]);

  useEffect(() => {
    if (!categories?.length) {
      axios.get("/api/categories").then(({ data }) => setCategories(data));
    }
  }, [categories?.length]);

  return (
    <Box css={styles.menu}>
      <Header open={isOpen} onToggle={onToggle} menuItems={menuItems} />

      <MuiMenu open={isOpen} anchor="left" onClose={onToggle} css={styles.menu__muimenu}>
        <Grid container p={1} bgcolor={theme.palette.primary.main} boxShadow={4} justifyContent="space-around">
          <Link href="/">
            <Stack direction="row" mt={0.2} justifyContent="center" alignItems="center">
              <Image src={LogoKMV} alt="Logo KMV" width={80} />
            </Stack>
          </Link>
          <Grid item xs={4} mt={1} ml={4} alignSelf="center" textAlign="end">
            <Tooltip title="Fechar">
              <StyledIcon aria-label="Fechar" onClick={onToggle} />
            </Tooltip>
          </Grid>
        </Grid>

        <List component="nav">
          {categories
            ?.filter((category) => category.partners?.length && category.visibleOnWebMenu)
            ?.map((category) => (
              <ItemMenu
                key={category.categoryId}
                categoryId={category.categoryId}
                name={category.name}
                icon={<Image src={category.icon} alt={category.name} width={24} height={24} />}
                items={category.partners}
                onClick={onClose}
              />
            ))}
        </List>

        <List css={styles.menu__list}>
          {menuItems?.map(({ title, url }, index) => (
            <div key={url}>
              <ListItem disablePadding css={styles.list__listitem}>
                <ListItemButton href={url} component={NextMuiLink} onClick={onToggle}>
                  <ListItemText primary={title} />
                </ListItemButton>
              </ListItem>
              {index != menuItems.length && <Divider css={styles.menu__divider} />}
            </div>
          ))}
          <ListItem disablePadding css={styles.list__listitem}>
            <ListItemButton href="/ajuda" component={NextMuiLink} onClick={onToggle}>
              <ListItemText primary="Ajuda" />
            </ListItemButton>
          </ListItem>
        </List>
      </MuiMenu>
    </Box>
  );
};
