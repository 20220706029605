import { DateInput, MaskedInput, TextInput } from "@/components/Inputs";
import { Grid, Typography } from "@mui/material";

export const PersonalData = () => {
  return (
    <>
      <Grid item xs={12}>
        <Typography fontSize={20} fontWeight={600}>
          Dados pessoais
        </Typography>
      </Grid>

      <Grid item xs={12} sm={6} md={6}>
        <MaskedInput name="document" id="document" label="CPF" placeholder="___.___.___-__" mask="###.###.###-##" disabled />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <DateInput name="birthDate" label="Data nascimento" sx={{ width: "100%" }} disabled />
      </Grid>

      <Grid item xs={12}>
        <TextInput fullWidth name="fullName" label="Nome completo" disabled />
      </Grid>
    </>
  );
};
