import { useSession } from "next-auth/react";
import Image from "next/image";
import React from "react";

import Logo from "@/assets/img/KMV_Logo_RGB_AzulTagline.png";
import Modal from "@/components/Modal/Modal";
import { useShoppingCart } from "@/context/ShoppingCartContext";
import { useToast } from "@/hooks/useToast";
import theme from "@/styles/themes/theme";
import { MaskUtils } from "@/utils/masks";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Document } from "@contentful/rich-text-types";
import { Avatar, Button, Card, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import { Copy, NewspaperClipping, Printer } from "@phosphor-icons/react";

import { styles } from "./styles";
import { OrderSummaryProps } from "./types";

export const OrderSummary: React.FC<OrderSummaryProps> = ({ isOpen, onClose }) => {
  const { checkoutResponse, cartItem } = useShoppingCart();
  const { data } = useSession();

  const { showToast } = useToast();

  const handleCopy = async () => {
    if (window.isSecureContext && navigator.clipboard) {
      await navigator?.clipboard
        ?.writeText(voucher ?? "")
        .then(() => {
          showToast({
            title: "Voucher copiado para a área de transferência",
            severity: "success"
          });
        })
        .catch(() => {
          showToast({
            title: "Houve um erro ao copiar o texto",
            severity: "error"
          });
        });
    } else {
      showToast({
        title: "Houve um erro ao copiar o texto",
        severity: "error"
      });
    }
  };

  const handlePrint = () => {
    window.print();
  };

  if (!checkoutResponse) {
    return null;
  }

  const { voucher, attention, instructions } = checkoutResponse;

  return (
    <>
      <Modal
        maxWidth="md"
        open={isOpen}
        onClose={(_, reason) => {
          /* Prevent modal close from backdrop */
          if (reason === "backdropClick") {
            return;
          }

          onClose();
        }}
        title="Voucher"
        buttons="Fechar"
        scroll="paper"
        css={styles.modal}
      >
        <Card css={styles.modal__card}>
          <CardHeader
            avatar={
              <Image
                src={cartItem?.productImage || cartItem?.partnerLogo || "/placeholder.png"}
                alt="Logo do Parceiro"
                width={82}
                height={40}
                style={{ objectFit: "contain" }}
              />
            }
            sx={{ bgcolor: theme.palette.common.white }}
          />

          <CardContent sx={{ p: 0, bgcolor: theme.palette.common.white }}>
            <Stack spacing={1} css={styles.modal__stack}>
              <Stack
                spacing={{ xs: 1, md: 2 }}
                direction={{ xs: "column", sm: "row", md: "row" }}
                alignItems={{ xs: "center", sm: "start", md: "start" }}
                css={styles.modal__stack_block}
              >
                <Image src={Logo} alt="Logo KMV" width={120} height={120} css={styles.modal__card_logo} />

                <Typography
                  fontSize={20}
                  fontWeight={500}
                  color={theme.palette.secondary.main}
                  textAlign={{ xs: "center", sm: "start", md: "start", lg: "start" }}
                  alignSelf="center"
                >
                  Parabéns, você trocou <span style={{ color: theme.palette.warning.main }}> {cartItem?.productKmPoints} pontos KMV </span> por:{" "}
                  {cartItem?.productDescription}.
                </Typography>
              </Stack>

              <Stack spacing={2} direction={{ xs: "column", sm: "row", md: "row" }} justifyContent="space-between" css={styles.modal__stack_block}>
                <Stack spacing={2} direction={{ xs: "column", sm: "row", md: "row" }} alignItems="center">
                  <Avatar variant="rounded" alt="avatar" css={styles.modal__avatar}>
                    <NewspaperClipping size={24} css={styles.modal__avatar_icon} />
                  </Avatar>

                  <Stack>
                    <Typography fontSize={10} color={theme.palette.secondary.main}>
                      Voucher:
                    </Typography>

                    <Typography fontSize={20} color={theme.palette.warning.main}>
                      {voucher}
                    </Typography>
                  </Stack>
                </Stack>

                <Stack spacing={2} direction={{ xs: "column", sm: "column", md: "row" }}>
                  <Button
                    variant="text"
                    color="secondary"
                    startIcon={<Copy color="black" />}
                    onClick={handleCopy}
                    css={styles.modal__buttonCopy_noPrint}
                  >
                    Copiar Voucher
                  </Button>

                  <Button variant="contained" startIcon={<Printer color="black" />} onClick={handlePrint} css={styles.modal__buttonPrint_noPrint}>
                    Imprimir Voucher
                  </Button>
                </Stack>
              </Stack>

              <Stack spacing={{ xs: 0, md: 2 }} direction="column" css={styles.modal__stack_block_cpf}>
                <Typography fontSize={12} fontWeight={700} color={theme.palette.secondary.main}>
                  CPF Titular do Km de Vantagens:{" "}
                  <span style={{ color: theme.palette.warning.main }}>{MaskUtils.maskCpf(data?.user?.basicInformations?.document)}</span>
                </Typography>
              </Stack>

              {attention || instructions ? (
                <Stack spacing={{ xs: 0, md: 4 }} direction="column" css={styles.modal__stack_block}>
                  {instructions ? (
                    <Typography fontSize={14} color={theme.palette.text.secondary} sx={{ wordWrap: "break-word" }} component="div">
                      <Typography fontSize={16} color={theme.palette.secondary.main} fontWeight={600}>
                        Como usar:
                      </Typography>
                      {typeof instructions === "object" ? documentToReactComponents(instructions as Document) : instructions}
                    </Typography>
                  ) : null}

                  {attention ? (
                    <Typography fontSize={14} color={theme.palette.text.secondary} sx={{ wordWrap: "break-word" }} component="div">
                      <>
                        <Typography fontSize={16} color={theme.palette.secondary.main} fontWeight={600}>
                          Atenção
                        </Typography>

                        {typeof attention === "object" ? documentToReactComponents(attention as Document) : attention}
                      </>
                    </Typography>
                  ) : null}
                </Stack>
              ) : null}
            </Stack>
          </CardContent>
        </Card>
      </Modal>
    </>
  );
};
