/* eslint-disable @typescript-eslint/ban-ts-comment */
import Router from "next/router";
import { useEffect, useState } from "react";

import theme from "@/styles/themes/theme";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Card, CardContent, CardHeader, Divider, Stack, Typography } from "@mui/material";

import { SimulatorUtils } from "../../utils/simulator";
import Modal from "../Modal/Modal";
import { PanelSimulation } from "./PanelSimulation";
import { ResultsSimulation } from "./ResultsSimulation";
import { SimulatorRegulation } from "./SimulatorRegulation";
import { styles } from "./styles";
import { ModalKmSimulatorProps } from "./types";
import { MARKS } from "@contentful/rich-text-types";

export const ModalKmSimulator = ({ isOpen, onClose, simulatorAccumulationInformativeText }: ModalKmSimulatorProps) => {
  const [value, setValue] = useState(20);
  const [place, setPlace] = useState("favoriteGasStation");
  const [methodPayment, setMethodPayment] = useState("ipirangaCreditCard");
  const [establishment, setEstablishment] = useState("combustivel");
  const [accumulatedKm, setAccumulatedKm] = useState(0);

  const handleOpenRegulation = () => {
    Router.push("/regulamento");
    onClose();
  };

  useEffect(() => {
    setAccumulatedKm(SimulatorUtils.calculation({ value: value, place: place, methodPayment: methodPayment, establishment: establishment }));
  }, [establishment, methodPayment, place, value]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title="SIMULADOR DE PONTOS KMV"
      maxWidth="lg"
      scroll="body"
      buttons="Fechar"
      /* @ts-ignore */
      dialogContentProps={{ css: styles.modal__content }}
    >
      <Stack direction="column" spacing={2} css={styles.modal}>
        <Card css={styles.modal__card}>
          <CardHeader
            title={
              <Typography fontSize={{ xs: 20, sm: 24, md: 24 }} fontWeight={300} color={theme.palette.secondary.dark}>
                SIMULE AQUI
              </Typography>
            }
            subheader={
              <Typography fontSize={{ xs: 20, sm: 24, md: 24 }} fontWeight={600} color={theme.palette.secondary.dark}>
                QUANTOS PONTOS KMV VOCÊ PODE GANHAR
              </Typography>
            }
          />

          <CardContent>
            <Stack direction={{ sm: "column", md: "row", lg: "row" }} spacing={2} rowGap={2}>
              <PanelSimulation
                value={value}
                setValue={setValue}
                place={place}
                setPlace={setPlace}
                methodPayment={methodPayment}
                setMethodPayment={setMethodPayment}
                establishment={establishment}
                setEstablishment={setEstablishment}
              />

              <ResultsSimulation value={accumulatedKm} />
            </Stack>

            <Stack spacing={2} mt={4}>
              <Typography component="div" fontSize={16} color={theme.palette.secondary.main}>
                {typeof simulatorAccumulationInformativeText === "object"
                  ? documentToReactComponents(simulatorAccumulationInformativeText, {
                      renderMark: {
                        [MARKS.BOLD]: (text) => <span style={{ fontWeight: 500 }}>{text}</span> // Fonte com peso 500 para negrito
                      }
                    })
                  : simulatorAccumulationInformativeText}
              </Typography>

              <Divider css={styles.modal__divider} />

              <Typography fontSize={14} color={theme.palette.secondary.main}>
                * Esta é uma simulação baseada no consumo médio mensal informado. O resultado é uma estimativa e não uma garantia de acúmulo. Para
                mais informações, confira as{" "}
                <span onClick={handleOpenRegulation} style={{ color: theme.palette.warning.main, cursor: "pointer" }}>
                  regras de limites de pontos.
                </span>
              </Typography>
            </Stack>
          </CardContent>
        </Card>

        <SimulatorRegulation handleOpenRegulation={handleOpenRegulation} />
      </Stack>
    </Modal>
  );
};
