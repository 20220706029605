import { useShoppingCart } from "@/context/ShoppingCartContext";
import theme from "@/styles/themes/theme";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { X } from "@phosphor-icons/react";

export const ShoppingCartHeader: React.FC = () => {
  const { removeItem } = useShoppingCart();

  return (
    <Stack p={2} direction="row" spacing={1} justifyContent="space-between" alignItems="center" width="100%">
      <Typography fontWeight={600} fontSize={16} color={theme.palette.text.secondary} mb={2}>
        Revisão de Resgate
      </Typography>

      <Tooltip title="Fechar">
        <IconButton aria-label="close" color="inherit" onClick={() => removeItem()}>
          <X fontSize="inherit" />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};
