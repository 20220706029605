import theme from "@/styles/themes/theme";
import { css } from "@emotion/react";

export const drawerWidth = 260;

export const styles = {
  modal__card: css`
    width: 100%;
    margin: 24px;
    margin-bottom: 0px;
    overflow: auto;
    padding-bottom: 24px;
    padding-left: 16px;
    padding-right: 16px;

    .MuiPaper-root &.MuiCard-root {
      border-radius: 4px;
      background-color: ${theme.palette.common.white};
    }

    @media (max-width: 600px) {
      padding-left: 0px;
      padding-right: 0px;
      margin-left: 0px;
      margin-right: 0px;
    }
  `
};
