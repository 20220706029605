interface ListPageBackProps {
  currentRoute: string;
  backRoute: string;
  hasBackButton: boolean;
  titleLabel: string;
}

export const listPageBack: ListPageBackProps[] = [
  {
    currentRoute: "/ouvidoria",
    backRoute: "/institucional",
    hasBackButton: true,
    titleLabel: "Voltar"
  },
  {
    currentRoute: "/seguranca",
    backRoute: "/institucional",
    hasBackButton: true,
    titleLabel: "Voltar"
  },
  {
    currentRoute: "/privacidade-protecao-dados",
    backRoute: "/institucional",
    hasBackButton: true,
    titleLabel: "Voltar"
  },
  {
    currentRoute: "/educacao-financeira",
    backRoute: "/institucional",
    hasBackButton: true,
    titleLabel: "Voltar"
  }
];
