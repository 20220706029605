import { useRouter } from "next/router";
import { useEffect } from "react";

import { NextMuiLink } from "@/components/Link/NextMuiLink";
import { useDisclose } from "@/hooks/utilHooks";
import { LinkUtils } from "@/utils/linkUtils";
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { CaretDown, CaretUp } from "@phosphor-icons/react";

import { styles } from "./styles";
import { ItemsMenuProps } from "./types";

const IconArrow = ({ Icon }: { Icon: React.ElementType }) => {
  return <Icon size={24} weight="bold" color="rgba(0, 0, 0, 0.54)" css={styles.icon} />;
};

export const ItemMenu = ({ name, icon, items, onClick, categoryId }: ItemsMenuProps) => {
  const { isOpen, onToggle } = useDisclose();
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = () => {
      onClick();
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.events]);

  return (
    <>
      <ListItemButton onClick={onToggle} css={styles.itembutton}>
        <ListItemIcon sx={{ minWidth: 40 }}>{icon}</ListItemIcon>
        <ListItemText primary={name} />
        {isOpen ? <IconArrow Icon={CaretUp} /> : <IconArrow Icon={CaretDown} />}
      </ListItemButton>

      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {items
            ?.filter((item) => item.visibleOnWebMenu)
            ?.map((partner) => (
              <NextMuiLink
                key={partner.partnerId}
                href={`/categoria/${categoryId}/parceiro/${partner.partnerId}`}
                target="_blank"
                css={styles.link}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  if (partner.url) {
                    LinkUtils.openLink(partner.url);

                    onClick();
                  } else if (partner.openInformativeFromMenu) {
                    router.push(`/categoria/${categoryId}/parceiro/${partner.partnerId}/informativo`);
                  } else {
                    router.push(`/categoria/${categoryId}/parceiro/${partner.partnerId}`);
                  }
                }}
              >
                <ListItemButton css={styles.collapse_itembutton}>
                  <ListItemText primary={partner.name} primaryTypographyProps={{ style: { fontSize: "14px" } }} />
                </ListItemButton>
              </NextMuiLink>
            ))}
        </List>
      </Collapse>
    </>
  );
};
