import { ExternalToast, toast } from "sonner";

import { ToastMessage } from "@/components/ToastMessage";
import { ToastMessageProps } from "@/components/ToastMessage/types";

interface IShowToast extends ToastMessageProps {
  config?: ExternalToast;
}

export const useToast = () => {
  const showToast = ({ title, description, severity, variant, config }: IShowToast) => {
    toast(<ToastMessage severity={severity} title={title} description={description} variant={variant} />, { style: { padding: 0 }, ...config });
  };

  return { showToast };
};
