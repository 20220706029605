import Image from "next/image";
import Link from "next/link";

import SignUp from "@/assets/img/app_kmv_home.webp";
import AppleStore from "@/assets/img/applestore.png";
import PlayStore from "@/assets/img/playstore.png";
import QRCode from "@/assets/img/qrcode_abasteceai.png";
import theme from "@/styles/themes/theme";
import { Stack, Typography, useMediaQuery } from "@mui/material";

import Modal from "../Modal/Modal";
import { styles } from "./styles";
import { SignUpModalProps } from "./types";

export const SignUpModal: React.FC<SignUpModalProps> = ({ isOpen, onClose }) => {
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <Modal
        open={isOpen}
        onClose={onClose}
        maxWidth="md"
        title="Faça seu cadastro"
        buttons="Fechar"
        dialogContentProps={{ sx: { bgcolor: theme.palette.common.white, paddingBottom: 0, px: 0 } }}
      >
        <Stack spacing={4} width="100%" direction="column">
          <Stack
            px={4}
            spacing={{ xs: 4, md: 0 }}
            display="flex"
            flexGrow={1}
            justifyContent="center"
            direction={{ xs: "column", sm: "row", md: "row" }}
          >
            <Stack css={styles.stack_img}>
              <Image src={SignUp} alt="Aplicativo KMV" css={styles.img} />
            </Stack>
            <Typography
              width={{ xs: "100%", sm: "100%", md: "90%" }}
              fontSize={{ xs: 24, sm: 24, md: 34 }}
              fontWeight={700}
              color={theme.palette.secondary.dark}
              alignSelf="center"
            >
              Faça agora seu cadastro pelo <span style={{ color: theme.palette.info.main }}>app KMV</span>
            </Typography>
          </Stack>

          <Stack py={4} px={{ xs: 4, sm: 6, md: 2 }} spacing={4} alignItems="center" css={styles.stack_download}>
            <Stack alignItems="start" spacing={1}>
              <Typography fontSize={{ xs: 24, sm: 24, md: 34 }} fontWeight={900} color={theme.palette.common.white}>
                Faça o download do <span style={{ color: theme.palette.primary.main, fontWeight: 700 }}>KMV</span>
              </Typography>
              <Typography fontSize={{ xs: 20, sm: 20, md: 24 }} color={theme.palette.common.white}>
                Acompanha você em todo caminho
              </Typography>
            </Stack>

            <Stack direction={{ xs: "column", sm: "row", md: "row" }} spacing={{ xs: 4, sm: 4, md: 8 }} alignItems={{ xs: "start", md: "center" }}>
              {!isMd && <Image src={QRCode} alt="Baixar app" />}
              <Link href="https://apps.apple.com/br/app/abastece-a%C3%AD-ofertas-ipiranga/id1052059383">
                <Image src={AppleStore} alt="Baixar na App Store" />
              </Link>
              <Link href="https://play.google.com/store/apps/details?id=com.gigigo.ipirangaconectcar">
                <Image src={PlayStore} alt="Baixar na Google Store" />
              </Link>
            </Stack>
          </Stack>
        </Stack>
      </Modal>
    </>
  );
};
