import React from "react";

import { Stack, Typography } from "@mui/material";

import { ContainerProps } from "./types";

export const Container: React.FC<ContainerProps> = ({ children, containerTitle, ...props }) => {
  return (
    <Stack py={4} px={{ lg: 16, md: 4, xs: 2 }} {...props}>
      {containerTitle ? (
        <Typography fontSize={24} textAlign="center" mb={2}>
          {containerTitle}
        </Typography>
      ) : null}

      {children}
    </Stack>
  );
};
