import theme from "@/styles/themes/theme";
import { css } from "@emotion/react";
import { alertClasses, dividerClasses, tooltipClasses } from "@mui/material";

export const styles = {
  usermenu: css`
    & .${tooltipClasses.tooltip} {
      background-color: ${theme.palette.common.white};
      color: rgba(0, 0, 0, 0.87);
      box-shadow: ${theme.shadows[5]};
      font-size: 11px;
      margin-top: 0 !important;
      max-width: 52em !important;
      border-radius: 16px;
      @media (max-width: ${theme.breakpoints.values.sm}px) {
        margin-top: 8px !important;
      }
    }
    & .${tooltipClasses.arrow} {
      color: ${theme.palette.common.white};
      transform: none !important;
      left: unset !important;
      right: 2rem !important;
      @media (max-width: ${theme.breakpoints.values.sm}px) {
        right: 110px !important;
      }
    }
    & .${alertClasses.root} {
      border-radius: 16px !important;
      margin-top: 4px;
    }
    & .${dividerClasses.root} {
      margin-top: 2px;
      margin-bottom: 2px;
      border-color: #0000001f;
    }
  `,
  usermenu__item: css`
    text-transform: none;
  `
};
