import { css } from "@emotion/react";

export const styles = {
  drawer__icon_shoppingcart: css`
    margin-right: 8px;

    @media (max-width: 384px) {
      width: 32px;
      height: 32px;
    }
  `
};
