import { Typography, TypographyProps } from "@mui/material";

import { NextMuiLink } from "../Link/NextMuiLink";
import { styles } from "./styles";

export const CaptchaFormFooter: React.FC<TypographyProps> = (props) => {
  return (
    <Typography component="span" variant="caption" color="gray" {...props}>
      Este site é protegido pelo reCAPTCHA e pelo Google aplicam-se a{" "}
      <NextMuiLink css={styles.link} href="https://policies.google.com/privacy" target="_blank">
        Política de Privacidade
      </NextMuiLink>{" "}
      e{" "}
      <NextMuiLink css={styles.link} href="https://policies.google.com/terms" target="_blank">
        Termos de Serviço
      </NextMuiLink>
      .
    </Typography>
  );
};
