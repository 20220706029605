import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { RecaptchaV3WrapperProps } from "./types";
import getConfig from "next/config";

export const RecaptchaV3Wrapper: React.FC<RecaptchaV3WrapperProps> = ({ children }) => {
  const { RECAPTCHA_SITE_KEY } = getConfig().publicRuntimeConfig;

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={RECAPTCHA_SITE_KEY}
      language="pt-BR"
      scriptProps={{
        async: false,
        defer: false,
        appendTo: "head",
        nonce: undefined
      }}
      container={{
        parameters: {
          badge: "inline",
          theme: "dark"
        }
      }}
    >
      {children}
    </GoogleReCaptchaProvider>
  );
};
