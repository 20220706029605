import theme from "@/styles/themes/theme";
import { css } from "@emotion/react";

export const styles = {
  container: css`
    background-color: ${theme.palette.grey[200]};
  `,
  container__divider: css`
    border: 1px solid rgba(0, 0, 0, 0.12);
    margin-left: 6px;
    margin-right: 6px;
  `,
  container__typography_superior: css`
    color: #000;
    word-break: break-word;
  `,
  container__typography: css`
    color: rgba(0, 56, 99, 0.5);
    word-break: break-word;
  `,
  container__link: css`
    text-decoration: none;
  `,
  container__link_abasteceai: css`
    text-decoration: none;
    font-size: 12px;
    color: ${theme.palette.warning.main};
  `
};
