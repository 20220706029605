import { useState } from "react";

import { IconButton, InputAdornment } from "@mui/material";

import { ITextInputProps, TextInput } from "./TextInput";
import { Eye, EyeSlash } from "@phosphor-icons/react";

export const PasswordInput: React.FC<ITextInputProps> = (props) => {
  const [visible, setVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setVisible(!visible);
  };

  return (
    <TextInput
      {...props}
      type={visible ? "text" : "password"}
      autoComplete="new-password"
      InputProps={{
        autoComplete: "new-password",
        endAdornment: (
          <InputAdornment position="end">
            <IconButton aria-label="Exibir/ocultar senha" onClick={togglePasswordVisibility} edge="end">
              {visible ? <Eye size={20} /> : <EyeSlash size={20} />}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
};
