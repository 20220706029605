import { css } from "@emotion/react";

export const styles = {
  inputbase: css`
    display: flex;
    width: 100%;
    align-self: center;
  `,
  textfield: css`
    & .MuiInputBase-root {
      display: flex;
      width: 100%;
      height: 48px;
      max-width: 600px;
      font-size: 16px;
      font-weight: 400;
      line-height: 16px;
      align-items: center;
      align-content: center;
      align-self: center;
      border-radius: 28px;
      background-color: #fff;
      border: 2px solid #ffc900;
      padding-left: 16px;
      gap: 16px;

      & .MuiOutlinedInput-notchedOutline {
        border: none;
      }

      @media (max-width: 1000px) {
        max-width: 500px;
      }
    }
  `,
  divider: css`
    margin: 0px 16px;
    height: 1px;
    border-color: rgba(0, 0, 0, 0.12);
    display: block;
  `,
  option: css`
    overflow: hidden;
    text-overflow: ellipsis;
    display: box;
    line-clamp: 3;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    width: 100%;
    text-decoration: none;
    color: #58595b;

    :hover {
      background-color: #f5f5f5 !important;
    }
  `,
  title: css`
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: inherit;
    width: 100%;
    text-decoration: none;

    :hover {
      font-weight: 700 !important;
    }
  `
};
