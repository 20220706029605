import theme from "@/styles/themes/theme";
import { css } from "@emotion/react";

export const styles = {
  headeritem: css`
    display: flex;
    align-items: center;
    justify-content: start;
  `,
  headeritem__button: css`
    text-transform: uppercase;
    font-weight: 500;
    font-size: 16px;
    color: ${theme.palette.secondary.main};
    &:hover {
      color: blue;
      background-color: none;
      margin-bottom: 2px solid blue;
    }
  `,
  headeritem__tabs: css`
    .MuiTabScrollButton-root {
      color: black;
    }
    .MuiTypography-root {
      font-weight: 600;
    }
  `,
  headeritem__question: css`
    min-width: 40px;
  `
};
