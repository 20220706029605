const calculation = ({
  value,
  place,
  methodPayment,
  establishment
}: {
  value: number;
  place: string;
  methodPayment: string;
  establishment: string;
}) => {
  // Combustível
  if (establishment === "combustivel") {
    //Local
    if (place === "favoriteGasStation") {
      // Método de pagamento
      if (methodPayment === "ipirangaCreditCard") {
        return value * 4;
      } else if (methodPayment === "anyCreditCard") {
        return value;
      } else if (methodPayment === "cash") {
        return 50;
      } else if (methodPayment === "abasteceAi") {
        return value * 2;
      }
    } //Local
    else if (place === "anyGasStation") {
      // Método de pagamento
      if (methodPayment === "ipirangaCreditCard") {
        return value * 2;
      } else if (methodPayment === "anyCreditCard") {
        return value;
      } else if (methodPayment === "cash") {
        return 50;
      } else if (methodPayment === "abasteceAi") {
        return value;
      }
    }

    // AMPM
  } else if (establishment === "ampm") {
    //Local
    if (place === "favoriteGasStation") {
      // Método de pagamento
      if (methodPayment === "ipirangaCreditCard") {
        return value * 2;
      } else if (methodPayment === "anyCreditCard") {
        return value;
      } else if (methodPayment === "cash") {
        return value;
      } else if (methodPayment === "abasteceAi") {
        return value;
      }
    } //Local
    else if (place === "anyGasStation") {
      // Método de pagamento
      if (methodPayment === "ipirangaCreditCard") {
        return value * 2;
      } else if (methodPayment === "anyCreditCard") {
        return value;
      } else if (methodPayment === "cash") {
        return value;
      } else if (methodPayment === "abasteceAi") {
        return value;
      }
    }

    // Jet Oil
  } else if (establishment === "jetoil") {
    //Local
    if (place === "favoriteGasStation") {
      // Método de pagamento
      if (methodPayment === "ipirangaCreditCard") {
        return value * 2;
      } else if (methodPayment === "anyCreditCard") {
        return value;
      } else if (methodPayment === "cash") {
        return value;
      } else if (methodPayment === "abasteceAi") {
        return value;
      }
    } //Local
    else if (place === "anyGasStation") {
      // Método de pagamento
      if (methodPayment === "ipirangaCreditCard") {
        return value * 2;
      } else if (methodPayment === "anyCreditCard") {
        return value;
      } else if (methodPayment === "cash") {
        return value;
      } else if (methodPayment === "abasteceAi") {
        return value;
      }
    }
  }
  return value;
};

export const SimulatorUtils = {
  calculation
};
