import { css } from "@emotion/react";

export const styles = {
  itembutton: css`
    padding-top: 4px;
    padding-bottom: 4px;
    padding-right: 15px;
    padding-left: 20px;
    height: 55px;
  `,
  collapse_itembutton: css`
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 72px;
  `,
  link: css`
    text-decoration: none;
    color: #212121;
  `,
  icon: css`
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 4px;
    padding-right: 4px;
    margin-right: 10px;
  `
};
