import Image from "next/image";
import { useFormContext } from "react-hook-form";

import FavoriteTeam from "@/assets/img/favoriteTeam.png";
import { SelectInput } from "@/components/Inputs";
import { Grid, MenuItem, Typography } from "@mui/material";

export const BelovedTeam = () => {
  const { watch } = useFormContext();
  const idFavoriteTeam = watch("favoriteTeam.id");
  const nameFavoriteTeam = watch("favoriteTeam.name");

  return (
    <>
      <Grid item xs={12}>
        <Typography fontSize={20} fontWeight={600}>
          Time do coração
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Image src={FavoriteTeam} alt="" />
        <Typography fontSize={16}>Nos diga qual é seu time do coração e receba notícias e conteúdo exclusivo.</Typography>
      </Grid>

      <Grid item xs={12}>
        <SelectInput id="favoriteTeam.id" name="favoriteTeam.id" label="Time favorito" placeholder="Selecione seu time favorito" disabled>
          <MenuItem value={idFavoriteTeam}>{nameFavoriteTeam}</MenuItem>
        </SelectInput>
      </Grid>
    </>
  );
};
