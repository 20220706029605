import React from "react";

import { Alert, AlertTitle } from "@mui/material";

import { ToastMessageProps } from "./types";

export const ToastMessage: React.FC<ToastMessageProps> = ({ severity, title, description, variant = "filled" }) => {
  return (
    <Alert severity={severity} sx={{ width: "100%", borderRadius: "8px" }} variant={variant}>
      <AlertTitle sx={{ fontWeight: 600, marginBottom: description ? "0.35em" : 0 }}>{title}</AlertTitle>

      {description}
    </Alert>
  );
};
