import getConfig from "next/config";
import Image from "next/image";
import Script from "next/script";
import React from "react";

const { publicRuntimeConfig } = getConfig();

/**
 * AppScripts component to include scripts that should be loaded on every page.
 * This component should be included in the _app.tsx file.
 */
export const AppScripts: React.FC = () => {
  return (
    <>
      {/* Google Tag Manager  */}
      <Script id="google-tag-manager-old" strategy="afterInteractive">
        {`
              (function(w,d,s,l,i) { 
                w[l] = w[l] || [];
                w[l].push({ 'gtm.start': new Date().getTime(),event:'gtm.js' });
                var f = d.getElementsByTagName(s)[0],
                  j = d.createElement(s),
                  dl = l != 'dataLayer' ? '&l=' + l : '';
                j.async = true;
                j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                f.parentNode.insertBefore(j, f);
              })(window, document, 'script', 'dataLayer', '${process.env.NEXT_PUBLIC_GTM_ID || "GTM-NJVMT6V"}');  
            `}
      </Script>

      {/* End Google Tag Manager  */}

      {/* TikTok Pixel  */}
      <Script id="tiktok-pixel" strategy="afterInteractive">
        {`
            !function (w, d, t) {
              w.TiktokAnalyticsObject=t;     
              var ttq = w[t] = w[t] || [];
              ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie","holdConsent","revokeConsent","grantConsent"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};
              for(var i=0; i < ttq.methods.length; i++)ttq.setAndDefer(ttq,ttq.methods[i]);
              ttq.instance=function(t){
                for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)
                  ttq.setAndDefer(e,ttq.methods[n]);
                  return e
                },ttq.load=function(e,n){var r="https://analytics.tiktok.com/i18n/pixel/events.js",o=n&&n.partner;
              ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=r,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};
              n=document.createElement("script");
              n.type="text/javascript",n.async=!0,n.src=r+"?sdkid="+e+"&lib="+t;
              e=document.getElementsByTagName("script")[0];
              e.parentNode.insertBefore(n,e)};
              ttq.load('${process.env.NEXT_PUBLIC_TIKTOK_PIXEL_ID || "CQV3K83C77U1RMG59GSG"}');
              ttq.page();
              }(window, document, 'ttq');
            `}
      </Script>
      {/* End TikTok Pixel  */}

      {/* Meta Pixel  */}
      <Script id="meta-pixel" strategy="afterInteractive">
        {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${process.env.NEXT_PUBLIC_META_PIXEL_ID || "1754163211787507"}');
          fbq('track', 'PageView');
        `}
      </Script>

      <noscript>
        <Image
          height="1"
          width="1"
          style={{
            display: "none"
          }}
          alt=""
          src="https://www.facebook.com/tr?id=1754163211787507&ev=PageView&noscript=1"
        />
      </noscript>
      {/* End Meta Pixel  */}

      {/* Google Tag Manager */}
      {/*  <Script id="google-tag-manager-new" strategy="afterInteractive">
        {`
         (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID || "GTM-KJ8T5QM6"}');
        `}
      </Script> */}

      {/* End Google Pixel  */}

      {/* One Pixel */}
      <Script id="OnePixel" strategy="afterInteractive">
        {`var i=new Image,u="https://s3-sa-east-1.amazonaws.com/frame-image-br/bg.png?x-id=49273caa4dcb7119c87900e4721da2df&x-r="+document.referrer+"&x-s="+window.location.href;i.src=u;`}
      </Script>
      {/* End One Pixel */}

      {/* OneTrust */}
      {!process.env.NEXT_PUBLIC_HIDE_ONE_TRUST ? (
        <Script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" data-domain-script="a36b5795-fc14-4578-bd3b-6edd0a5e26f8"></Script>
      ) : null}
      {/* End OneTrust */}

      {/* Google tag (gtag.js) */}
      <Script
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${publicRuntimeConfig.NEXT_PUBLIC_GOOGLE_ANALYTICS || "G-L5SLHSYM3N"}`}
      />
      <Script strategy="lazyOnload" id="GoogleTag">
        {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${publicRuntimeConfig.NEXT_PUBLIC_GOOGLE_ANALYTICS || "G-L5SLHSYM3N"}', {
                page_path: window.location.pathname,
                });
              `}
      </Script>
      {/* End Google tag (gtag.js) */}
    </>
  );
};
