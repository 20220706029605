import { validateCPF } from "validations-br";
import * as Yup from "yup";

import { MaskUtils } from "@/utils/masks";
import { yupResolver } from "@hookform/resolvers/yup";

const requiredField = "Campo requerido";

const cpfValidation = Yup.string().test("is-cpf", "CPF inválido", (value: string | undefined) => {
  if (!value) {
    return false;
  }

  const unmasked = MaskUtils.unmaskNumber(value);

  const cpf = unmasked.length < 11 ? `0${unmasked}` : unmasked;

  return validateCPF(cpf);
});

const passwordSchema = Yup.string().length(6, "A senha deve ter exatamente 6 dígitos").required(requiredField);

export const formLoginSchema = yupResolver(
  Yup.object().shape({
    cpf: cpfValidation.required(requiredField),
    password: passwordSchema
  })
);
