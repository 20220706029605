import { Button, Stack } from "@mui/material";
import { FilePdf } from "@phosphor-icons/react";

import { SimulatorRegulationProps } from "./types";

export const SimulatorRegulation = ({ handleOpenRegulation }: SimulatorRegulationProps) => {
  return (
    <Stack>
      <Button
        color="secondary"
        size="small"
        startIcon={<FilePdf />}
        onClick={handleOpenRegulation}
        sx={{ width: "fit-content", alignSelf: "center" }}
      >
        Confira o Novo Regulamento
      </Button>
    </Stack>
  );
};
