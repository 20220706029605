import { format, parseISO } from "date-fns";
import IMask from "imask";

export const INPUT_MASKS = {
  CPF: "000.000.000-00",
  MOBILE_PHONE: "(00) 00000-0000",
  BRAZILIAN_ZIPCODE: "[00000]{-}[000]",
  BRAZILIAN_DATE: "00/00/0000",
  CREDIT_CARD: "0000-0000-0000-0000-000",
  CREDIT_CARD_EXPIRES: "00/0000",
  SIX_DIGITS: "000000"
};

const unmaskNumber = (num: string) => {
  return (num ?? "").replace(/[^\d]+/g, "");
};

export const cpfMask = IMask.createMask({
  mask: INPUT_MASKS.CPF
});

export const creditCardMask = IMask.createMask({
  mask: INPUT_MASKS.CREDIT_CARD
});

export const creditCardExpiresMask = IMask.createMask({
  mask: INPUT_MASKS.CREDIT_CARD_EXPIRES
});

export const mobilePhoneMask = IMask.createMask({
  mask: INPUT_MASKS.MOBILE_PHONE
});

/**
 * Returns the CPF formatted, using this model: `000.000.000-00`.
 * @param cpf - String
 */
const maskCpf = (cpf?: string) => {
  if (!cpf) {
    return "";
  }

  return cpfMask.resolve(cpf);
};

/**
 * Returns the Date formatted, using this model: `00/00/0000`.
 * @param date - String
 */
const maskDate = (date: string | Date) => {
  if (!date) {
    return "";
  }

  if (typeof date === "string") {
    return format(new Date(parseISO(date)), "dd/MM/yyyy");
  }

  return format(new Date(date), "dd/MM/yyyy");
};

/**
 * Returns the Date formatted, using this model: `01 de Janeiro de 2023`.
 * @param inputDate - String
 */
const maskDateExtended = (inputDate: string) => {
  const dateObj = new Date(inputDate);
  const day = dateObj.getUTCDate();
  const month = dateObj.getUTCMonth();
  const year = dateObj.getUTCFullYear();

  const monthNames = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];

  return `${day} de ${monthNames[month]} de ${year}`;
};

/**
 * Return the value formatted, using the brazilian currency R$ as model
 */
const maskCurrency = (value: number | string): string => {
  return new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(Number(value));
};

/**
 * Returns formatted credit card expires date, using this model: `00/00`.
 * @param creditCard - String
 */
const maskCreditCard = (creditCard: string) => creditCardMask.resolve(creditCard ?? "");

/**
 * Returns formatted credit card, using this model: `0000-0000-0000-0000-0000`.
 * @param {string} expires
 */
const maskCreditCardExpires = (expires: string) => creditCardExpiresMask.resolve(expires ?? "");

/**
 * Returns formatted mobile phone, using this model: `(00) 00000-0000`.
 */
const maskMobilePhone = (phone: string) => mobilePhoneMask.resolve(phone ?? "");

export const MaskUtils = {
  maskCpf,
  unmaskNumber,
  maskDate,
  maskDateExtended,
  maskCurrency,
  maskCreditCard,
  maskCreditCardExpires,
  maskMobilePhone
};
