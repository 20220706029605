/* eslint-disable @typescript-eslint/no-unused-vars */
import { useController, UseControllerProps } from "react-hook-form";

import { OutlinedTextFieldProps, styled, TextField as MUITextField } from "@mui/material";

export interface ITextInputProps extends Omit<OutlinedTextFieldProps, "variant">, UseControllerProps {
  name: string;
  defaultValue?: string | number;
  variant?: "standard" | "filled" | "outlined";
}

export const TextInput: React.FC<ITextInputProps> = ({
  name,
  rules,
  shouldUnregister,
  defaultValue,
  control,
  variant = "outlined",
  InputProps,
  ...props
}) => {
  const { field, fieldState } = useController({
    name,
    rules,
    shouldUnregister,
    defaultValue,
    control
  });

  return (
    <MUITextField
      error={Boolean(fieldState.error?.message)}
      variant={variant}
      autoComplete="new-password"
      fullWidth
      onChange={field.onChange}
      onBlur={field.onBlur}
      value={field.value ?? ""}
      helperText={fieldState.error?.message}
      {...props}
      InputProps={{ autoComplete: "new-password", ...InputProps }}
    />
  );
};

export const TextInputUnstyled = styled(TextInput)(({ theme }) => ({
  "& .MuiInputBase-root > fieldset": {
    borderWidth: "0px !important",
    boxShadow: "none !important"
  },
  "& .Mui-focused": {
    boxShadow: "none",
    borderWidth: 0
  }
}));
