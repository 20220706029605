import { createContext, Dispatch, ReactNode, RefObject, SetStateAction, useContext, useEffect, useRef, useState } from "react";

import { LoginRef } from "@/components/Login/types";

type LoginProviderProps = {
  children: ReactNode;
};

export interface LoginContextProps {
  modalRef: RefObject<LoginRef>;
  setPage: Dispatch<SetStateAction<string>>;
}

export const LoginContext = createContext<LoginContextProps>({} as LoginContextProps);

export const LoginProvider: React.FC<LoginProviderProps> = ({ children }) => {
  const modalRef = useRef<LoginRef>(null);
  const [page, setPage] = useState<string>("");

  useEffect(() => {
    const listener = (e: MessageEvent) => {
      const data = e.data;

      e.preventDefault();

      if (data.action === page) {
        modalRef.current?.onOpen();
      }
    };
    window.addEventListener("message", listener);

    return () => window.removeEventListener("message", listener);
  }, [page]);

  return (
    <LoginContext.Provider
      value={{
        modalRef,
        setPage
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};

export const useLogin = () => {
  return useContext(LoginContext);
};
